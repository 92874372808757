var team21 = [
  [{}, {}],
  [
    {
      name: "Govind Gupta",
      post: "Coordinator",
      fb: "https://www.facebook.com/profile.php?id=100005799362676",
      insta: "https://www.instagram.com/govind_gupta_46/",
      linkedin: "https://www.linkedin.com/in/govind-gupta-b96b1b197/",
      mail: "mailto:govindg@iitk.ac.in",
      image: "team21/21cordi1.jpg",
    },
    {
      name: "Janhavi Bhoge",
      post: "Coordinator",
      fb: "https://www.facebook.com/janhavi.bhoge.5",
      insta: "https://www.instagram.com/janhavinainamohanbhoge/",
      linkedin: "https://www.linkedin.com/in/janhavi-b-38b8801b2",
      mail: "mailto:janhavim@iitk.ac.in",
      image: "team21/cordi2.jpg",
    },
    {
      name: "Nidhi",
      post: "Coordinator",
      fb: "https://www.facebook.com/nidhi.kansal.2412",
      insta: "https://www.instagram.com/_nidhi_kansal/",
      linkedin: "https://www.linkedin.com/in/nidhi-kansal-70978a20b/",
      mail: "mailto:nidhik@iitk.ac.in",
      image: "team21/cordi3.jpg",
    },
    {
      name: "Rohan Kumar",
      post: "Coordinator",
      fb: "https://www.facebook.com/rohankj01/",
      insta: "https://www.instagram.com/rohankj01/",
      linkedin: "https://www.linkedin.com/in/rohankj01/",
      mail: "mailto:rohankj@iitk.ac.in",
      image: "team21/cordi4.jpg",
    },
  ],
  [
    {
      name: "Mandvi Rajpoot",
      post: "Secretary, Events",
      fb: "https://www.facebook.com/mandvi.rajpoot.739",
      insta: "https://www.instagram.com/im_mandvi/",
      linkedin: "https://www.linkedin.com/in/mandvi-rajpoot-8311721b8",
      mail: "mailto:mandviraj20@iitk.ac.in",
      image: "team21/e2.jpg",
    },
    {
      name: "Narendra Prajapat",
      post: "Secretary, Events",
      fb: "https://www.facebook.com/profile.php?id=100057304671312",
      insta:
        "https://www.instagram.com/invites/contact/?i=16w6jwegaupuf&utm_content=jr2e6dk",
      linkedin:
        "https://www.linkedin.com/in/narendra-jugalkishor-prajapat-2a1401218",
      mail: "mailto:nprajapat20@iitk.ac.in",
      image: "team21/e3.jpg",
    },
    {
      name: "Nikhil Singh",
      post: "Secretary, Events",
      fb: "https://www.facebook.com/profile.php?id=100062545143992",
      insta:
        "https://www.instagram.com/invites/contact/?i=t207bnwq6s0x&utm_content=knbys6r",
      linkedin: "https://www.linkedin.com/in/nikhil-singh-042bb020a",
      mail: "mailto:snikhil20@iitk.ac.in",
      image: "team21/e4.jpg",
    },
    {
      name: "Payal",
      post: "Secretary, Events",
      fb: "https://www.facebook.com/profile.php?id=100057792575783",
      insta: "https://www.instagram.com/payal_singh_0981/",
      linkedin: "http://linkedin.com/in/payal-singh-aa6272218",
      mail: "mailto:payalk20@iitk.ac.in",
      image: "team21/e5.jpg",
    },
    {
      name: "Shikha Singh",
      post: "Secretary, Events",
      fb: "http://facebook.com/profile.php?id=100060702617127",
      insta: "https://www.instagram.com/sshikha_223/",
      linkedin: "https://www.linkedin.com/in/shikha-singh-6482a2210",
      mail: "mailto:sshikha20@iitk.ac.in",
      image: "team21/e6.jpeg",
    },
    {
      name: "Susmitha Amarthaluri",
      post: "Secretary, Events",
      fb: "https://www.facebook.com/susmitha.amarthaluri",
      insta: "https://www.instagram.com/__susmitharaj__",
      linkedin: "https://www.linkedin.com/in/susmitha-amarthaluri-193353215/",
      mail: "mailto:susmitha20@iitk.ac.in",
      image: "team21/e7.jpg",
    },
    {
      name: "Kaushal Kumar Jha",
      post: "Secretary, Content Writing",
      fb: "https://www.facebook.com/kaushal.jha.71404",
      insta: "https://www.instagram.com/kaushalkj2955/",
      linkedin: "https://www.linkedin.com/in/kaushal-kumar-jha-314982214",
      mail: "mailto:kaushalkj20@iitk.ac.in",
      image: "team21/c1.jpg",
    },
    {
      name: "Kritika Bansal",
      post: "Secretary, Content Writing",
      fb: "https://www.facebook.com/kritika.bansal.587606",
      insta: "https://instagram.com/kritika_bansal2?utm_medium=copy_link",
      linkedin: "https://www.linkedin.com/in/kritika-bansal-0a2b88217",
      mail: "mailto:kritikab20@iitk.ac.in",
      image: "team21/c2.jpg",
    },
    {
      name: "Om Jee",
      post: "Secretary, Content Writing",
      fb: "https://www.facebook.com/profile.php?id=100057579249812",
      insta: "https://soce-iitk.github.io/soce/sections/team/#",
      linkedin: "https://www.linkedin.com/in/om-jee-322b12212",
      mail: "mailto:omjee20@iitk.ac.in",
      image: "team21/c3.jpg",
    },
    {
      name: "Prabhat Kumar Meena",
      post: "Secretary, Content Writing",
      fb: "https://www.facebook.com/prabhat.meena.906",
      insta: "https://www.instagram.com/prabhhatkumarr/",
      linkedin: "https://soce-iitk.github.io/soce/sections/team/#",
      mail: "mailto:prabhatm20@iitk.ac.in",
      image: "team21/c4.jpg",
    },
    {
      name: "Sanidhya Shrivastava",
      post: "Secretary, Content Writing",
      fb: "https://www.facebook.com/sanidhya.shrivastava.3",
      insta:
        "https://www.instagram.com/sanidhya.shrivastava.1402/?utm_medium=copy_link",
      linkedin: "https://soce-iitk.github.io/soce/sections/team/#",
      mail: "mailto:sanidhyash20@iitk.ac.in",
      image: "team21/c5.jpg",
    },
    {
      name: "Shobhit Lamba",
      post: "Secretary, Content Writing",
      fb: "https://www.facebook.com/shobhit.lamba.507/",
      insta: "https://www.instagram.com/shobhiit.2159/",
      linkedin: "#",
      mail: "mailto:shobhit20@iitk.ac.in",
      image: "team21/c6.jpg",
    },
    {
      name: "Aman Kumar Singh",
      post: "Secretary, Design",
      fb: "https://www.facebook.com/amanks20",
      insta: "https://www.instagram.com/protonaman",
      linkedin: "https://www.linkedin.com/in/amanks20/",
      mail: "mailto:amanks20@iitk.ac.in",
      image: "team21/d2.jpg",
    },
    {
      name: "Shubham Kumar",
      post: "Secretary, Design",
      fb: "https://www.facebook.com/profile.php?id=100058188695519",
      insta: "https://www.instagram.com/shubham.kumar_007/",
      linkedin: "https://www.linkedin.com/mwlite/in/shubham-kumar-9926b9209",
      mail: "mailto:shubhamku20@iitk.ac.in",
      image: "team21/d5.jpg",
    },
    {
      name: "Ajeet Kushwaha",
      post: "Secretary, Design",
      fb: "https://www.facebook.com/profile.php?id=100057608348317",
      insta: "https://www.instagram.com/ajeet_iitk/",
      linkedin: "https://www.linkedin.com/in/ajeet-kushwaha-0b581b210",
      mail: "mailto:ajeet20@iitk.ac.in",
      image: "team21/d1.jpg",
    },
    {
      name: "Shruti Hansika",
      post: "Secretary, Design",
      fb: "https://www.facebook.com/shruti.hansika",
      insta: "https://instagram.com/__shrutii.ii?utm_medium=copy_link",
      linkedin: "https://www.linkedin.com/in/shruti-hansika-62878b207",
      mail: "mailto:shrutih20@iitk.ac.in",
      image: "team21/d4.jpg",
    },
    {
      name: "Faiza khan",
      post: "Secretary, Web Development",
      fb: "https://www.facebook.com/profile.php?id=100037231279653",
      insta: "https://www.instagram.com/faizak31/",
      linkedin: "https://www.linkedin.com/in/faiza-khan-b21b71200/",
      mail: "mailto:faizakhan20@iitk.ac.in",
      image: "team21/w2.jpg",
    },
    {
      name: "Kumar Abhishek",
      post: "Secretary, Web Development",
      fb: "https://www.facebook.com/kabhishek20/",
      insta: "https://www.instagram.com/k_abhishek.15/",
      linkedin: "https://www.linkedin.com/in/kumar-abhishek-629303216/",
      mail: "mailto:kabhishek20@iitk.ac.in",
      image: "team21/w3.jpg",
    },
    {
      name: "Saurabh Sahay",
      post: "Secretary, Web Development",
      fb: "https://www.facebook.com/saurabh.sahay.395",
      insta: "https://www.instagram.com/sahay.saurabh/",
      linkedin: "https://www.linkedin.com/in/saurabh-sahay-b20797197/",
      mail: "mailto:ssahay20@iitk.ac.in",
      image: "team21/w4.jpg",
    },

    {
      name: "Yuvraj Singh",
      post: "Secretary, Web Development",
      fb: "https://www.facebook.com/profile.php?id=100033277658507",
      insta: "https://www.instagram.com/__yuvisofficial/",
      linkedin: "https://www.linkedin.com/in/yuvraj-singh-b6955b1aa",
      mail: "mailto:syuvraj20@iitk.ac.in",
      image: "team21/w5.jpg",
    },

    // {
    //     name: "Aman Kumar Singh",
    //     post: "Secretary, Design",
    //     fb: "https://www.facebook.com/amanks20",
    //     insta: "https://www.instagram.com/protonaman",
    //     linkedin: "https://www.linkedin.com/in/amanks20/",
    //     mail: "mailto:amanks20@iitk.ac.in",
    //     image: "/img/team/team21/d2.jpg"
    // },

    // {
    //     name: "Shubham Kumar",
    //     post: "Secretary, Design",
    //     fb: "https://www.facebook.com/profile.php?id=100058188695519",
    //     insta: "https://www.instagram.com/shubham.kumar_007/",
    //     linkedin: "https://www.linkedin.com/mwlite/in/shubham-kumar-9926b9209",
    //     mail: "mailto:shubhamku20@iitk.ac.in",
    //     image: "img/team/team21/d5.jpg"
    // },
    {
      name: "Ashish Gautam",
      post: "Secretary, Marketing",
      fb: "https://www.facebook.com/profile.php?id=100009844478632",
      insta: "https://instagram.com/patronus__charm___?utm_medium=copy_link",
      linkedin: "https://www.linkedin.com/in/ashish-gautam-21540a20b",
      mail: "mailto:ashishg20@iitk.ac.in",
      image: "team21/m1.jpg",
    },
    {
      name: "Rahul Kumar",
      post: "Secretary, Marketing",
      fb: "https://www.facebook.com/100017952162303/",
      insta: "https://www.instagram.com/rciitk20/",
      linkedin: "https://www.linkedin.com/in/rahul-choudhary-27132b218",
      mail: "mailto:rahulk20@iitk.ac.in",
      image: "team21/m2.jpg",
    },
    {
      name: "Sheshank",
      post: "Secretary, Marketing",
      fb: "https://www.facebook.com/profile.php?id=100011601156445",
      insta: "https://instagram.com/i_m_sheshank?utm_medium=copy_link",
      linkedin: "https://www.linkedin.com/in/sheshank-b9771b214",
      mail: "mailto:sheshank20@iitk.ac.in",
      image: "team21/m3.jpg",
    },
    {
      name: "Shivangi",
      post: "Secretary, Marketing",
      fb: "https://www.facebook.com/shivam.nauhwar.10",
      insta: "https://www.instagram.com/shivu_nhr/?utm_medium=copy_link",
      linkedin: "https://www.linkedin.com/in/shivangi-nauhwar-044b12217/",
      mail: "mailto:shivangi20@iitk.ac.in",
      image: "team21/m4.jpg",
    },
    {
      name: "Animesh Tiwari",
      post: "Secretary, Finance",
      fb: "https://www.facebook.com/animesh.tiwari.9231",
      insta: "https://instagram.com/tiwarianimesh_?utm_medium=copy_link",
      linkedin: "https://www.linkedin.com/in/animesh-tiwari-120a35213",
      mail: "mailto:animesht20@iitk.ac.in",
      image: "team21/f1.jpg",
    },
    {
      name: "Devansh Singh",
      post: "Secretary, Finance",
      fb: "https://www.facebook.com/profile.php?id=100057238750157",
      insta: "https://www.instagram.com/deva_rajpoot_/",
      linkedin: "https://www.linkedin.com/in/devansh-singh-303a52213/",
      mail: "mailto:devanshs20@iitk.ac.in",
      image: "team21/f2.jpg",
    },
    {
      name: "Sahil Aggarwal",
      post: "Secretary, Finance",
      fb: "https://www.facebook.com/profile.php?id=100012073338415",
      insta:
        "https://www.instagram.com/invites/contact/?i=1e3mp4f76wl0r&utm_content=1wtd828",
      linkedin: "http://www.linkedin.com/in/sahil-aggarwal-a3a348212",
      mail: "mailto:sahila20@iitk.ac.in",
      image: "team21/f3.jpg",
    },
  ],
];

module.exports = team21;
