import React from 'react'
import './blogs.css'
const Vineet_Shukla = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Vineet-Shukla.jpg' className='blog-dp' alt='Vineet'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Vineet Shukla</h2>
                            <small></small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>Greetings everyone,
        <br/>
        I am Vineet Shukla, a Y17 Civil Engineering student currently hustling through all major formalities of the
        final semester at IITK. I am at home, for now, struggling to recover my lost sense of smell and taste,
        <img class="blog-image-left" src="placement-postulates/blogs/Vineet-Shukla/1.jpg"/>
        waiting for my joining, and convincing people to write in my yearbook. My life at IITK has been full of
        highs and lows, dubious junctures, euphoria, and awes. From starting with a CPI of 8.6 to ending with
        something around 7, from not being able to decide which club to join, to being acting president of gymkhana
        for a while, from hustling for a foreign research internship to landing a job as an Associate Product
        Manager at Groww. To be focused on the placements, I will skip scribbling about my first year. But mostly, I
        will skip it because it was not unreasonably fascinating.
      </p>
      <p>
        I think my sophomore year was the hardest part of college. Rigorous academics, an uncertain future, and fear
        of missing out were the primary reasons for the difficulties I confronted. Yet three major things happened
        to me in the subsequent year; I became a senator, I started acquiring a research interest, and what’s more,
        I started working on my startup. All things considered, these three independent events were the most
        important influence on my college life.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Vineet-Shukla/2.JPG"/>
      <p>
        Where winning senate elections helped me come out of my introversion and made way for some major PoR roles
        for me, research interest swept more inconspicuously. I was always overwhelmed by the idea of going abroad
        for an internship or an exchange program. So I started exploring what civil engineering has got to offer in
        research. However, my not-so-great CPI by then limited the number of ways I could go abroad. I would not be
        considered for exchange programs, nor internship programs like MITACS or DAAD. So the only possible way was
        to email professors, and count on luck that they might reply. This was a painful task. Googling
        universities, discovering professors, and then sending them personalized emails. That being said, not many
        of them replied and most of them were rejections. Unfortunately, even after emailing around 800 people
        around half of the globe, I could not land an internship. This was a major setback.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Vineet-Shukla/3.JPG"/>
      <p>
        When my hopes for getting a foreign internship shattered, I took a step back (which is my self-claimed
        specialty in basketball as well). I stayed in college for second-year summers and did a research project
        under Prof. Shivam Tripathi. Additionally, I was playing out as acting president. It was also the time when
        I began working on my startup. It quickly escalated from random internet surfing to a serious group of 5
        people dreaming to become millionaires right after graduation. At one time, I had thoughts that I will put
        all my eggs in one basket and focus only on the startup. I learned a lot of stuff about startups and product
        management while I was working on this self-project, and I think that had a major influence on my career
        track somehow or another. With no internship and falling CPI, I had hit rock bottom but with inspiration. I
        could only go up from here.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Vineet-Shukla/6.jpg"/>
      <p>
        With the beginning of the third year, my adoration for an abroad internship had not left me yet. You see my
        biggest dilemma. I cannot pick between things. I was doing good at research. A very unconventional thing,
        startup, I was also doing fine at that. But if I had to choose one of them, it would have been nearly
        impossible for me. So in the fifth semester, I again started emailing professors. I had accepted that I had
        bigger odds of getting a research internship than landing at a good campus internship. Remember guys, it
        does not matter how many rejections you get, you only have to crack one of them. After repeating the process
        countless times, I was finally interviewed by four professors and got offers from all of them. The
        universities I am talking about are Kyoto University, UNSW Sydney, TUM Germany, and the Hebrew University of
        Jerusalem. I chose Jerusalem because it was the only fully funded offer I got. Unfortunately, I could not go
        there because of COVID. But I did work with them from home. My project was ML application-based, and I think
        it did well on my confidence, and also reflected well on my resume.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Vineet-Shukla/7.jpg"/>
      <p>
        Hereby I come to the most crucial piece of the blog you have been waiting for, the placement season. By now,
        I had an ML-based internship, a reasonably good PoR, and a project. Enough to make a decent resume. Over the
        years, there has been no lack in explaining the importance of a good resume, so I need not mention it. I
        began my placement preparation quite late, around September which is not the ideal time to start with the
        prep. I was targeting ML and analytics roles, and for that, I prepared in the standard way. Practiced quant
        and guesstimates, did puzzles, probability and statistics, revised ML, etc. I faced the disadvantage of
        marginally less CPI. My CPI was 6.9 by the end of the sixth semester and many companies put 7 as their
        criteria for test shortlisting. It was demoralizing not to find your name on the test shortlist just because
        your CPI is 0.1 less than the qualifying. So to the folks who have a lesser CPI, I strongly advise bringing
        your CPI to the mark of 7 if it is possible. The number of companies that will open up for you will increase
        significantly. However, there is a silver lining for people like me who will be having difficulty finding
        their names in interview shortlists: do not be discouraged folks, a wise senior once said to me, “ job to
        sabki lagti hai.” Many companies release their extended shortlists right before their interviews. And there
        is a good possibility that you get shortlisted for that. Including me, several other seniors got into
        companies they were not at first shortlisted in. They got either through extended shortlists or walk-in
        interviews.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Vineet-Shukla/8.jpg"/>
      <p>
        There were only three companies this year that offered product management roles: Flipkart, Zomato and Groww,
        and even among them, Flipkart did not recruit anyone from campus, Zomato took four, and Groww took two. The
        positions offered are so few by product companies that you can never bank on just them during placement
        preparation. However, one advantage of the PM roles is that they don’t generally look at your CPI (Zomato
        APM exception). Flipkart and Groww were shortlisted for the interview solely based on the PPT round. Groww
        PPT round asked us to prepare 8-9 slides on a topic, and the shortlist was done based on that. It is a
        tedious task to make a PPT while all your placement tests are going on. If a couple of my friends had not
        pushed me for it, I might even have skipped it, and I would have been in a completely different role in some
        other company. I somehow managed to submit the PPT at the last minute. This half-hearted effort brought
        about me not being shortlisted for the interview round at Groww. Other companies that I was shortlisted in
        were: Finmechanics, Icertis, Axis Bank, ICICI Bank, and Praxis. All were in the analytics/ ML role category.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Vineet-Shukla/10.jpg"/>
      <p>
        I got acquainted with the fact that I am being considered for the interview only 15 minutes before that. It
        was a last-minute shortlist which made me extremely nervous. Ill-prepared and unequipped, I suited up for
        the interview at 4:00 in the morning. There were three interview rounds. The first and second rounds were
        more technical, focused on product-related case studies and few guesstimates. In the second round, the
        interviewee asked me to show my resume. But I am not sure what particularly he needed to find in it because
        he told me to close it in only two MISSISSIPPIS. I had not practiced product-specific case studies, but I
        had an advantage through working on my startup. My third interview was taken by the CEO himself and it is
        too good to be true. He asked me only one question and that was, “Tell me something that you have built.” I
        felt that this question was made for me. Next moment I was standing in front of the whiteboard I had,
        smoothly pitching my startup idea to him. Yes, that was my final round. I would like to remind you that I
        had not mentioned the startup in my resume because at that time there was no documented success in the
        project. By the end of the third round, I was confident that my interviews went well. And half an hour
        later, I got another call stating that I have been considered for the job. I got placed at placement round
        2.1.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Vineet-Shukla/11.jpeg"/>
      <p>
        In retrospect, I feel that none of the things that I had done was a conscious step towards this final job
        that I got. Quoting Steve Jobs, ‘ you cannot connect the dots looking forward; you can only connect them
        looking backward.’ Who would have thought that working on a startup would help me in such a bizarre way? My
        achievements have been a blend of luck, coincidences, and lots of random gigs and experiences. Placement
        season could be tough but in the end, you are gonna do well. It is always a good practice to look sharp,
        have an optimistic approach, be up-to-date with the company’s work and culture that you are interviewing
        with, and show genuine interest in joining them. Nature has its way of giving back to you. All the best!
      </p>
      <p>
        Some product-related material:
      <ul>
        <li><a class="links"
            href="https://docs.google.com/document/d/1361vh1zuKTrCXR_DH0WVzJ1cBWjMWJHnpSqtg6jwTOs/edit">Product
            Management Resources</a></li>
      </ul>
      </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vineet_Shukla