import React from 'react'
import './blogs.css'
const Dishaa_Rajora = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Disha-Rajora.jpg' className='blog-dp' alt='aryaman'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Dishaa Rajora</h2>
                            <small></small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>
        Before I begin with the story of my placement journey, let us have introductions first. Hi. I am Dishaa Rajora,
        final year undergraduate majoring in Civil Engineering (wow, this introduction gave me
        formal-email-to-professor-don’t-know-how-to-begin-the-email feel). I am the UG nominee at Women’s Cell and
        currently placed at PWC, which is a consulting firm, and this is my story.
      </p>
      <img class="blog-image-left" src="placement-postulates/blogs/Dishaa-Rajora/1.jpeg"/>
      <b>No, I did not get a campus internship in my third year.</b><br/>
      <p>
        Internship season could be dreadful. At least it was for me. I did not like to code, so I didn’t prepare for it.
        Neither did I think that working on my aptitude was something I should do. The aptitude questions looked so easy
        when I opened them
        up one day in summer? The result: I wasn’t getting shortlisted anywhere. My resume was alright since I did have
        a second-year internship at Airbus, but what good was it if I wasn’t getting shortlisted for interviews? Most
        companies were for
        software profiles, and I didn’t even remember the syntax. As for the companies taking aptitude tests, I was too
        slow. Others were much, much prepared. All of my friends had internship offers, and I was the only one left.
      </p>
      <p>I felt like I was sinking. As time passed by, I stopped going out of my room and meeting people. I didn’t feel
        like it. I was taking so much stress that I started losing sleep. I started overthinking and questioning myself.
        Thankfully, I had
        some amazing people in my life who would come and hang out with me, forcefully take me out to parties, or play
        board games in my room, and some seniors who would always check up on me, even miles apart. I started feeling
        better again. The love
        of all those people worked. I then started trying for internships off-campus, both through foreign internships
        and LinkedIn. After 100+ emails, I landed a research internship at Scotland under a former Oxford University
        professor and an offer
        from Deloitte. Eventually, I didn’t get to go to Scotland because of COVID, but it still feels pretty amazing to
        be chosen. In all, my internship season taught me many things, the areas where I needed to work, patience, and
        real friendship.</p>
        <img class="blog-image-right" src="placement-postulates/blogs/Dishaa-Rajora/2.jpeg"/>
      <b>No, I did not know Data Structures</b>
      <p> I started my placement preparation during mid-July (tip: the earlier you start, the better), and this time I
        did practice basic coding and aptitude. That being said, I did not indulge in learning Data Structures because,
        to be frank, I don’t
        like coding that much and software profile wasn’t my first choice. If you aim for software, you should learn
        Data Structures as they come in handy during placement tests. I started coding through Interview Bit, and I only
        focused on two topics:
        Strings and Arrays. These were the two most likely topics to be asked (other than DS), in my opinion, and so I
        started with them. It was tough. When I began the practice, I couldn’t even think where to begin, but by the end
        of two months, I
        could code well. I did get shortlisted for Software and Data Science roles. Practice is the key.</p>
      <b>Yes, I did prepare for Aptitude</b>
      <p>
        Aptitude questions are pretty basic, but they need practice. To be able to solve all the questions in time, you
        need to know tricks for each question and have a fast calculation. This only comes through practice. Trust me,
        everybody prepares
        for this. I started with Prepleaf and then bought an aptitude package from IMS. I used to give aptitude tests
        almost every other day. The more you familiarize yourself, the better.
      </p>
      <b>Yes, it did take me three weeks to make my resume</b>
      <img class="blog-image-left" src="placement-postulates/blogs/Dishaa-Rajora/3.jpeg"/>
      <p>
        Resume making is a crucial step during placement preparations and much more if you aim for consulting firms.
        Consulting firms do not take any tests but shortlist candidates solely based upon their resumes. Your resume is
        the only thing about
        placements that is under your control. You will make multiple drafts of your resume; nobody gets it perfect on
        their first attempt. I must have made 10+ drafts myself. Get your resume verified with people who have secured
        jobs in the same
        profile. Seniors will surely help. The sooner you finish with your resume, the earlier you can continue with
        your placement preparation. Make sure to make a resume for different profiles. It will be a little frustrating,
        but what else can we
        do?
      </p>
      <p>
        Do not underestimate course projects. I got shortlisted for Blackrock because I had a finance project that I did
        as part of my grade. If you feel like your resume is empty, try picking up some projects or internships. You
        could also fill it up
        with PORs, but a balanced resume is ideal. My resume had two internships (Deloitte, Airbus), three projects
        (UGP, course project, summer project), two PORs (Women’ Cell, Company Coordinator), some pretty basic
        extracurricular achievements. In
        all, kind of balanced.
      </p>
      <b>Yes, I did prepare rigorously for my interview at PWC</b>
      <img class="blog-image-right" src="placement-postulates/blogs/Dishaa-Rajora/4.jpeg"/>
      <p>
        The interview schedule for PWC was posted a month before the scheduled interview date. They were the first to
        release their interview shortlist. Consulting interviews require a different kind of preparation as they require
        students to solve
        case studies. Don’t get scared because of the name. These are just problem statements that you need to solve by
        asking for data from your interviewer. They check your thinking process and confidence. There are multiple books
        available, but you
        should start with the IITB case book and Victor Cheng videos on Youtube.
      </p>  
      <p>
        I prepared with multiple people who were also sitting for consulting interviews. Reading cases will never help;
        you have got to practice them with people. I remember disturbing my friends who were not sitting for placements
        at even 3 am to take
        my mock interview. (Thank you, Faizan Siddiqui, Shashwat Shukla, and Shashidhar Nayak, for doing that :P )
      </p>
      <p>
        I wouldn’t lie and tell you that it was easy. The whole journey was a rollercoaster ride. There were many days
        where I felt like I wouldn’t get placed, but then I would call up Rushabh Gandhi, and he would tell me to shut
        up. Well, that’s what
        friends do. Right? Rishabh Ramola and I would call each other up after every exam and just laugh at our
        miserable states. We would make ridiculous plans of starting our Coaching Classes if we don’t get placed. Haha.
        Spoiler alert: We both did
        get placed.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Dishaa-Rajora/5.jpeg"/>
      <p>
        Before my interview at PWC, which was scheduled at midnight, my friends called me up just to chit chat. Probably
        because they know I was a big overthinker, and chatting would ease my mind. I talked to them till 11:55, and
        they made every joke
        possible on my clothes, hair, face, and everything. I did get placed that night, and the best part, all my
        friends were up late that night just to hear about my interviews even though theirs were scheduled in the
        morning and some were not even
        sitting for placements. Placements will make you anxious, but if you surround yourself with genuine people, who
        may or may not be going through the same thing, you will feel truly blessed.
      </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dishaa_Rajora