import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";

export default function CarouselModal({
  children: slides,
  onClose,
  imageHeight,
}) {
  const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  const next = () =>
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  // Set the maxHeight of the modal container based on the imageHeight prop
  const modalMaxHeight = `${imageHeight}px`;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-white w-3/4 md:w-1/2 lg:w-1/3 rounded-lg shadow-lg relative"
        style={{ maxHeight: modalMaxHeight }}
      >
        <div className="relative overflow-hidden">
          <div
            className="flex transition-transform ease-out duration-500"
            style={{ transform: `translateX(-${curr * 100}%)` }}
          >
            {slides}
          </div>
          <div className="absolute inset-0 flex items-center justify-between p-4">
            <button
              onClick={prev}
              className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
            >
              <ChevronLeft size={40} />
            </button>
            <button
              onClick={next}
              className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
            >
              <ChevronRight size={40} />
            </button>
          </div>

          <div className="absolute bottom-4 right-0 left-0">
            <div className="flex items-center justify-center gap-2">
              {slides.map((_, i) => (
                <div
                  key={i}
                  className={`transition-all w-3 h-3 bg-white rounded-full ${
                    curr === i ? "p-2" : "bg-opacity-50"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>

        <button
          onClick={onClose}
          className="absolute top-[10px] right-[10px] p-2 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
        >
          Close
        </button>
      </div>
    </div>
  );
}
