import React from 'react'
import './blogs.css'
const Shashi_Shekhar = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Shashi-Shekhar.jpg' className='blog-dp' alt='shashi'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Shashi Shekhar</h2>
                            <small>Placed in Blackrock</small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>Hi people!<br/>
      Let me share my story. So, I am Shashi Shekhar. I got placed in Blackrock on Day 1 during the placement season 20-21. My extra-curricular involvements changed with every year, but the major ones have been Manager- Research, Academics and Career
      Council and Senator, Y17.
    </p><img class="blog-image-left" src="placement-postulates/blogs/Shashi-Shekhar/1.jpg"/>
    <p>
      In the first 3-4 semesters, I was not sure if I was going to sit for the placements, so I didn’t give much heed to the preparation or developing relevant technical skills (read coding/finance etc). The one thing that I did well was to explore
      the fields I would be interested in or to get myself in a situation where I can challenge myself (honestly recommend maintaining balance while doing this). Now, the thing is, if you are living like this, you are just one step away from a big
      mess. And the big mess arrived during the 4th sem. I didn’t like any of my extra-curricular activities, my focus on studies went haywire. As a result, my CPI took a big hit, and I couldn't get myself anything good to pursue in the summers. So I
      just took a project under one of our professors. I had decided at this point that I was going to sit for internships. But the Cricket world cup came (did help me during the interview ), and my internship preparation went nowhere. The project,
      like most of the IITK projects, never got finished.
    </p>
    <p>
      So, I was sitting for internships with almost no technical projects/ internships (ok, there was one, thanks to the IITK Consulting Group) and a lot of PORs. Now cut to the start of covid lockdown (at the end of the 6th sem). You must have
      imagined, I was sitting with no internship in my hand. It wasn’t the case that I didn’t give interviews (I gave 6-7 interviews, even gave an interview for Goldman Sachs, to this day I have no idea how I got shortlisted for it).
    </p><img class="blog-image-right" src="placement-postulates/blogs/Shashi-Shekhar/2.jpg"/>
    <p>
      A few good things came out during that time. I realised that without academics everything is fatuous, so I worked on my academics. My CPI increased considerably (at the end of the 6th sem it was 7.6, increased from 6.9 in the 4th sem). I took
      part in the IWIS summit in December after my 5th sem. It was a great experience to meet some renowned personalities during that summit. Also, this summit became a very good point in my resume.
    </p>
    <p>
      Covid Lockdown- This was the best time. I Had a lot of time to think about my life, introspect my past decisions. Tried to find what I would want to do in life (wasn’t very successful in this).
    </p>
    <p>
      Clarity is a must before starting anything. I knew I hadn't coded much after ESC101, and I didn’t like to code, so I did a few courses/projects on ML on Coursera, kaggle and got a data science internship on Internshala. I was lucky that I came
      in contact with one of our seniors and he was working on his startup idea. So, I worked with him during the summers in the product development and research domain (there is no such division of role in new startups, just made this name because I
      wanted to write in my resume and my work-related most closely to this).
    </p><img class="blog-image-left" src="placement-postulates/blogs/Shashi-Shekhar/3.jpg"/>
    <p>
      I had already decided that I was going to sit for placements. I decided that I wasn’t going to sit for Software development roles. I started preparing in mid-July. I used to try to code every day on interviewbit/ leetcode/ gfg (could only
      maintain a streak of 2-3 days), along with the courses/projects that I was doing to build my resume.
    </p>
    <p>
      I gave a lot of time to resume making and it was worth it. I made 4 resumes. One each for analyst, finance, core (I knew there was only one company i.e. Tata projects, but still made it), and software(for MAYDAY). I didn’t have much to write
      for the finance role but did 1-2 small projects and few courses online so that my resume had something relevant. I had 2-3 online ML projects, 2 internships, the same PORs that I had during internship time; In the extra-curricular, I added the
      summit and a few things I did in the 1st two years.
    </p>
    <p>
      Now the placement tests began, I didn’t sit for most of the software roles, so until mid-November I had enough time to prepare anything I felt necessary (did my aptitude preparation, probs and stats, preparation for consulting/APM roles, coding
      practice). From Mid November, the number of tests Increased considerably and parallelly my stress (it is always good to identify your stress busters before this journey).
    </p>
    <p>
      Before Day 1 I had one shortlist each for consulting, finance, analyst and software. A very difficult situation to be in. My first interview was in Blackrock.
    </p><img class="blog-image-right" src="placement-postulates/blogs/Shashi-Shekhar/4.jpg"/>
    <p>
      Interview preparation- I revised all the projects and internships the week before placements. The company research was done parallelly. Then I gave 2 mock interviews (thanks prepleaf for this). I also talked to the senior who got shortlisted
      for the same profile last year (It was of great help).
    </p>
    <p>
      I had only 1 company (Blackrock) in that slot.<br/>
      The 1st round of the interview went for around 50 minutes. Interview started from resume grilling, and then they asked a few puzzles, questions about the profile and company, 1 coding question (only pseudo code) and an SQL query, and a few
      general questions).
      <br/>
      The 2nd round was the HR round (went for 45 minutes). He asked a few questions from my resume and project and after that, he asked almost all questions from the top 50 hr questions list.
    </p>

    <p>
    <img class="blog-image-left" src="placement-postulates/blogs/Shashi-Shekhar/5.jpg"/>
    <h2>
      <u>
        Few tips and tricks-
      </u>
    </h2>
    <ol>
      <li>
        Be very clear about the profiles/role you want to sit in.
      </li>
      <li>
        Start your preparation as early as you can. I started around July and I felt that I should have started earlier.
      </li>
      <li>
        If you are not coding, you are leaving yourself with very few options. So, do the practice. In most of the analyst roles, there was a coding round.
      </li>
      <li>
        Everybody is good with aptitude. So, if you want to get a shortlist, increase your speed. ½-1 hr of consistent practice would suffice( prepleaf and CAT books/coaching material are good sources).
      </li>
      <li>
        Look into the Job description of companies opening for your profile. And try to add the relevant points/skills in your resume. I looked into the previous year list of companies and made my resume accordingly
      </li>
      <li>
        Get your resume verified with the relevant seniors. I even checked with a few of my batchmates and they gave the best advice.
      </li>
      <li>
        Don’t get disheartened about low cpi or no internship. You can only control the controllable. So, try to prepare as best as you can. You can find enough seniors who got placed despite these challenges.
      </li>
      <li>
        While preparing, be in contact with your friends who are preparing for similar roles. Try to help each other. It saves a lot of time and helps in continuity.
      </li>
      <li>
        During preparation, work on your conversation skills. A lot of students get rejected just because they are not able to present their views.
      </li>
      <li>
        Sometimes questions repeat in the tests. They could be from the previous year or maybe asked in another IIT. So, always go through those questions before the test.
      </li>
      <li>
        Try to identify and correct mistakes that you may be committing. I used to make note of things which I thought I needed to improve.
      </li>
      <li>
        There could be a case that you may not be getting shortlisted for interviews. Don’t lose hope because of this. You will get enough opportunities due to the extended shortlists.
      </li>
      <li>
        The interview is the most important step. I would honestly recommend giving mock interviews.
      </li>
      <li>
        Try to make the interview an interaction rather than a question-answer thing. For example, in my interview, we even talked about the cricket world cup, meditation, and the meaning of life.
      </li>
      <li>
        Smile/ laugh before the interview starts. It improves your energy, makes you more confident.
      </li>
      <li>
        Help your peers. Point out each other’s mistakes and work on them.
      </li>
      <li>
        If you have still not been able to get yourself placed. Rather than going on a guilt trip, try to find the reasons and work accordingly. A few of my batchmates got placed off-campus.
      </li>
    </ol>
    </p>
    <p>
      Thanks for bearing with me in this. I hope you show the same perseverance in your preparation.<br/>
      P.S.- I haven’t added any preparation links. Kindly look into the blogs of Ashutosh Ranjan and Krishan Kumar. They have added the relevant ones.
    </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shashi_Shekhar