export default function ArchiveInfo() {
    return (
        <div className="container" id="student">
            <div className="row stu">
                <div className="col">
                    <h1>Archive</h1>
                    <p>
                       Archive of the events we have conducted so far
                    </p>
                </div>
            </div>
        </div>
    );
}
