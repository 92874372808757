import React from 'react'
import './blogs.css'
const Piyush_Senwar = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Piyush.jpg' className='blog-dp' alt='Piyush'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Piyush Senwar</h2>
                            <small>Associate Data Science Engineer at Micron Technology</small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>
                Hi everyone, this is Piyush Senwar, who will be joining Micron Technology as an Associate Data Science Engineer. Through this postulate, I would like to take you to my placement journey, what challenges I faced, a bit of my journey on how I got into data science, and most importantly, how you can grab a good placement if you make honest efforts for the preparation. I bet many of you would try to relate your case with mine with no idea of internship and placements at the end of the second year who had spent the first two years just chilling out and with no significant skill set and on-campus internship at the end of my 5th semester. With just one semester and summer left before placements and you have a big task ahead of you. 
I had many questions in my mind, and many of you would also be wondering them, like, how many companies would open for civil ?, Cpi cutoffs ?, Will I be able to get a sufficient shortlist for interviews ? and many more. Will try to cover most of them. Please be patient to read it, and I hope you won't regret it. 

            </p>
            <p>
                <b>Internship Misery </b> <br/>
                Starting with the Internship season. Internship season came, horrors of esc101 were already there, but I gathered courage and started coding. Began with the interview bit; it's a good platform to begin, with all the concepts in a particular order, although still, you have to look here and there on youtube to clear your concepts and then practice questions from the interview bit. I didn't enjoy it, and I had to see the hints or solutions most of the time, though I wouldn't encourage you to do it at all. And that's why I was never confident at coding in my internship tests. So this fear of coding led me to look for an alternative where I started my journey in data science. I began with basic data analysis courses from Coursera in python. I then did some machine learning courses from Coursera itself and worked on some random datasets. That working on some random dataset and a first-year summer snt project was my major project on my resume. Hence my resume was also miserable; the only good point was my CPI. I had a decent CPI to clear the cutoffs for many tests. So that was all my preparation for the internship - basic coding and basics of Machine learning were my major skills. I failed and eventually ended up with no on-campus internship. So I hope many of you would be able to relate the same. Obviously, I was devastated as many of my friends had already secured an internship. 

            </p>
            <p> <img class="blog-image-left" src="placement-postulates/blogs/Piyush-Senwar/1.jpg"/>
                <b>Planning for Placements </b> <br/>
 
                
I had to get up with some plans with two major tasks in front of me with one semester in hand and then a summer before placement season started. The two tasks were - firstly, increasing the skill level, whether that be coding or data science concepts, and secondly, preparing a good resume. Luckily I had this wonderful friend of mine, Satyam Prakash, and we both started planning for it. And that's why having a good company is very important in an internship as well as placement season. Now to make a good resume, we needed to have a good project. I started contacting many professors from IITK as well as other major IITs and some major NITs also as a backup. And believe me, there will be many professors who will be ready to give you projects. All you have to do is to write a good email telling of your interests and your determination to 
learn something and then do a copy-paste it to the professor's mailbox, but still, students tend to be lazy and procrastinate. I got a project from one of the professors of the CSE department at IIT Kharagpur, which was related to machine learning. So the task of increasing the skill level and getting a good project was done slightly through this project. I enhanced my machine learning and deep learning concepts with hands-on coding practice.  <br/>
Remember, it will always be you yourself who has to work hard and learn new things, and no one will spoon-feed you, especially if you are from the civil department. I tried to learn ML/DL through Coursera courses, Kaggle projects, Medium/Towards DataScience blogs( wonderful blogs), and StackOverflow to debug your code. This is how you will gain an edge over other competitors and clear the basics of data science. <br/>
Now with this project in hand in the 6th semester, I was also constantly putting efforts into finding an internship through off-campus opportunities. I was so desperate to get one and was constantly applying. And that frustration of not getting an internship was always there for most of the 6th semester. Finally, my efforts had paid off, and I was able to secure an off-campus internship, not a great one but nevertheless a decent internship to get some work experience and to fill my resume. Here I would like to focus on one more important point: securing an internship will only give a bonus to your resume but in the end, what matters is your skillset and its execution in the interviews. <br/>
 So that planning and its execution were paying off finally, and that's where I realized if you put in your honest efforts, you will definitely get something in the end. 
            </p>
            <p> <img class="blog-image-right" src="placement-postulates/blogs/Piyush-Senwar/2.jpg"/>
            </p>
            <b>Back to Coding </b> <br/>
            <p>
            Summers came, and I was satisfied with my resume now that I had a decent project, an internship, and a decent CPI. I took a web development project under Professor Veena Bansal, and that's where my interest in the SDE role greatly began. So I started working on increasing my coding skills. Again I, with my friend Satyam, began with an interview bit; since I was not much confident at coding, he was the one who always motivated me to practice questions more and more. We discussed coding problems, gave leetcode, and pclub archives contest. I would recommend starting coding as early as possible because I believe is that learning the coding concepts is a gradual process and more of a practice. You would get an idea of the concept at first look, but when you would revisit the same concept again, you would get a much better idea of it, and that's when you would grasp these concepts much better and eventually end up in enjoying coding as well. Trust me, the constant efforts from your side will make you enjoy coding in the end. <br/>
 I began coding in June since my main focus in the 6tth semester was getting an internship. Even if you are late like me, trust me, two months of hard work and constant efforts in June-July would make you a lot more confident for the tests. Practice daily for at least 4-5 hours on coding. With practicing questions from interview bit, leetcoade, and binarysearch.com also try to give the archive contest organized by pclub even if you are not well prepared but try to give them. It's not necessary that you do all the questions, but you will end up realizing what would be the level of questions that you would face in your placement tests. I made this mistake but I don't want you to do the same mistake because many of these questions would repeat in your placement tests, that's for sure.  <br/>
Now, what are important topics you must do? Obviously, you should first of all cover the basic topics array, string, heap, and bitwise. But the special emphasis on graphs is a must - bfs, dfs, dijkstra. Then comes Binary Search and DP. Try to do not so trivial binary search problems. Try to do pclub archives; you will get a better idea.  <br/>
            </p>
            <p>
               
                <b>How to prepare for Data Science? </b> <br/>
                With coding, I was also trying to clear my machine learning/ deep learning concepts. In data science it's very important that instead of gaining more and more incomplete knowledge it's more important that you work on the basics of whatever concept you are learning, for a simple example if you are reading about CNN - Convolutional Neural Network you should understand why CNN is better for image classification, what is the purpose of different layers used, why padding is done, why data augmentation, these are some small concepts which you should rather work on instead of understanding any concept incompletely i.e upar upar se padna, avoid it. These are the small things that interviewers focus on and ask you in the interviews. They won't ask you hi-fi concepts but will check your basics. Now because data science has many fields - ML, DL, RL, NLP, etc, and many algorithms, you just can't get expertise in all but rather try to gain expertise in what you have written in your resume at least. Resources for these are straightforward, just google whatever you want to learn you will find good Medium and Towards DataScience articles. Revise concepts through online courses and youtube. And try to do hands-on coding to get a feel of those concepts. The best way for this is to do work on some Kaggle projects or even many Coursera courses that have labs associated with them for practice.  <br/>
Statistics is a field that is closely linked with data science, and this will be tested in your placement tests as well in interviews. I remember questions being asked on t-tests, hypothesis testing, and distributions even in interviews so if you have forgotten your HSO concepts try to revise them through an online course. It takes a week with 1-2 hours daily to complete a course easily.  <br/>
Apart from coding and data science preparation, I did practice for puzzles and aptitude but never put an honest effort into it, and as expected, I was out of shortlisting in many tests which asked questions on aptitude. So don't think that you will get away with the tests without practicing them because everyone would be easily able to do those questions; the difference lies in the speed at which you do them. Companies always ask the same repeated puzzles, so try to do the first interview bit and gfg puzzles and for quant you can go for the 50 challenging puzzles book and brainstellar. For Apti just practise, you will get bored but do the practice. I also learned the basics of SQL, trying to get its basics, at least with writing queries, not just the theory part. 
An important thing to note here is that you should be confident in the field you are preparing for. Don't try to do everything but rather put more effort into those which really interest you and where you really want to go. If data science doesn't suit you, try to put more effort into coding, you will have a lot of opportunities for the SDE role and vice-versa for data science. If both don't suit you, you would have already seen some postulates regarding other profiles and will see more to come and get to know how they got placed. <br/>

            </p>
            <p> <img class="blog-image-left" src="placement-postulates/blogs/Piyush-Senwar/3.jpg"/>
                <b>Tests and Interviews  </b> <br/>
                The placement tests probably start at the end of September or October beginning. Slowly and gradually, the daily test count will increase. So try to maintain a balance between the tests, placement preparation, and academics. CPI cutoffs depend from company to company. Most companies have a cutoff of 7+ or 7.5+. Some companies have their own shortlisting criteria, which can be weird a lot of times and will surprise you, but you can't do much, so don't get frustrated but rather focus on other companies. Do not try to give all the tests that come your way, be confident because there will be a lot of companies coming and a lot of opportunities, don't worry about whether you will get shortlisted or not. Don't worry about what would be your chance of getting shortlisted among the 1200-1500 students sitting for placements. I had the same doubts, but you will get shortlisted. There are waiting lists also and later walk-in interviews, so don't worry much about whether you will get on the shortlist or not. 
The awaited day came, i.e., December 1, day 1 of placements. Since I had prepared for the two major profiles, SDE and Data Science, I had a shortlist in both these profiles. I was shortlisted for Da-Vinci derivatives, Sprinklr, and finmechanics on the day 1 slot 1 aka. as 1.1. Da-vinci went horrible as I was asked questions on networking, system design, and operating system which I had no idea about. Don't worry; this company was only open for circuital branches so we're expecting that knowledge but the application procedure was a general form which I filled and then gave the test and got shortlisted. For finmechanics, they probably preferred students with finance knowledge. For the Sprinklr, I would like to give important advice for interviews. Try to schedule your interviews as early as possible by contacting the POCs for whatever companies you are mainly targeting; otherwise, it would be too late as some companies don't interview later. This is what happened with me for Sprinklr interviews. Due to other interviews and clashing, my round 1 was scheduled later, it went well and I was informed by the POC that you would have the 2nd round. But the second never happened, for some students, even the HR round didn't happen after two successful rounds just because it was too late and the company had already hired the students they needed. Don't be discouraged from it, rather be active in those nervous scenarios. Slot 1 started at 12 in midnight, and in the desperation of getting a call for 2nd round I ended up waking till 8 in the morning. Nothing happened, I was frustrated, stressed, and had only a 2-3 hour sleep. 1.2 interviews started at 12 noon. I had interviews for American Express analytics role whose 1st round was easily cleared due to my ML knowledge, but in the 2nd round, I felt miserable as it was a case study, and I hadn't prepared at all for that. 
I was now feeling desperate to get an offer. Next on day 1 slot 2 itself, I had an interview for Micron Technology for the role of Data Scientist. It went pretty well, only 1 round but it went with continuous questions being thrown at me related to ML, DL, statistics concepts, and puzzles with different people coming in the zoom call at regular intervals, it went for about 1 hour. After the interview, I felt pretty confident about getting an offer. 

            </p>
            <p>
            <img class="blog-image-right" src="placement-postulates/blogs/Piyush-Senwar/4.jpg"/>
                <b>Finally Placed </b> <br/>

                Now, most of the companies had declared the final hiring list of students for day 1, but Micron was yet to declare. Most of my wingies and friends were placed, and there was chaos in the 
corridors of the hall with many celebrating, but my result was still not out. I felt so stressed and cried. Lakshay Rastogi, one of my wingies, tried to motivate me, he made me aware of the other shortlist I had on day 2. I had mock interviews with him. Another piece of advice here would be to do mock interviews with your friends; this will greatly help you to find your limitations and help later be confident for the interviews. Day 2 slot 1 started at 4 in the morning. I was not able to sleep, so I woke up early. And immediately contacted one of the OPCs for the result, and he told me that I was placed at Micron Technology. I felt so relieved and happy that finally, the things I had prepared paid off.  <br/>
I learned from the interviews that you need to be calm in such a nervous and stressed environment. You will have a lot of opportunities; there will be waitlists and even later walk-in interviews. I remember that on day 2, I had a HiLabs shortlist for both data science and SDE roles, PayU was there, and I was getting calls for interviews from Rakuten, Razorpay, Accenture, and Girnar. So a few bad interviews should not demotivate you much but rather try to learn from them what went wrong and discuss with friends and try to improve them.  <br/>
Finally, an honest effort in the preparation will definitely get you placed somewhere good. Never think I don't have enough time, but rather think about the time you are left with and start preparing from now itself. Try to enjoy this journey with your preparation rather than being stressed. <br/> 
Don't hesitate to ping me for any queries, any questions you have in your mind, any preparation help you need, or any interview and test experiences.  <br/> <br/>
Best of luck! 
            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Piyush_Senwar