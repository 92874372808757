var web = [
    [],
    [
        {

            name: "Saurabh Sahay",
            post: "Y20 UG",
            fb: "https://www.facebook.com/saurabh.sahay.395",
            insta: "https://www.instagram.com/sahay.saurabh/",
            linkedin: "https://www.linkedin.com/in/saurabh-sahay-b20797197/",
            mail: "mailto:ssahay20@iitk.ac.in",
            image: "sauravweb.jpg"
        },
        {
      name: "Shubham Kumar",
      post: "Y20 UG",
      fb: "https://www.facebook.com/profile.php?id=100058188695519",
      insta: "https://www.instagram.com/shubham.kumar_007/",
      linkedin: "https://www.linkedin.com/mwlite/in/shubham-kumar-9926b9209",
      mail: "mailto:shubhamku20@iitk.ac.in",
      image: "cordi4.jpeg"
    },
    ],
    [
    {
            "name": "Aashruti Raj",
            "post": " Y21 UG",
            "fb": "https://www.facebook.com/profile.php?id=100075348576412",
            "insta": "https://www.instagram.com/invites/contact/?i=1pvjy1qrcpo18&utm_content=kevllzc",
            "linkedin": "",
            "mail": "mailto:aashruti21@iitk.ac.in ",
            "image": " w1.jpg"
        },

        {
            "name": "Arpit Kaithwar",
            "post": " Y21 UG",
            "fb": "https://www.facebook.com/profile.php?id=100075288250644",
            "insta": "https://www.instagram.com/arpit_kaithwar_/",
            "linkedin": "http://www.linkedin.com/in/arpit-kaithwar-ak83035",
            "mail": "mailto:akaithwar21@iitk.ac.in",
            "image": " w3.jpg"
        },

        {
            "name": "Saurav Kumar",
            "post": " Y21 UG",
            "fb": "https://www.facebook.com/profile.php?id=100035038578902",
            "insta": "https://www.instagram.com/kr_saurav35/",
            "linkedin": "https://www.linkedin.com/in/saurav-kumar-1894a5226",
            "mail": "mailto:sauravkr21@iitk.ac.in",
            "image": " w5.jpg"
        },
        {
            "name": "Rishi Ratn",
            "post": " Y21 UG",
            "fb": "https://www.facebook.com/profile.php?id=100075245231208",
            "insta": "https://www.instagram.com/rishi_ratn/",
            "linkedin": "https://www.linkedin.com/in/rishi-ratn-53b4b4232/",
            "mail": "mailto:rishiratn21@iitk.ac.in",
            "image": "rishi.jpg"
        }
    ],
    
]

module.exports = web;
