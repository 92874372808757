import React from 'react'
import './blogs.css'
const Gourav_Kamboj = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Gaurav-Kamboj.jpg' className='blog-dp' alt='aryaman'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Gourav Kamboj</h2>
                            <small>Software engineer at HSBC</small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>
        <b>“Placement and Internship”</b> are the most frequent words that keep howling in our mind probably after the
        2nd year and sometimes leaving no space to think of anything else. This phase comes in the life of the majority
        of us if not all.
      </p>
      <p>
        Greetings everyone,
        <br/>
        I’m Gourav Kamboj, Y17 UG in the dept. of Civil Engineering at IIT Kanpur. Here I will be talking about my
        journey at IITK to getting placed in HSBC as a software engineer.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Gourav-Kamboj/1.jpg"/>
      <p>
        My journey at IITK… I was a kind of a student who was finding it difficult to decide if a particular
        field/domain is interesting to me or not even after working in it. This was because I hadn't explored enough to
        make a career-related decision.
        After doing ESC101, I was like I’m not going into coding because it sucked me at that time.
      </p>
      <p>
        Along with that I was taking interest in athletics and did summer camp after 1st year. <br/>
        In my extracurricular, I was in the organising team of Udghosh’18.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Gourav-Kamboj/2.jpg"/>
      <p>
        After doing some core courses in the 2nd year, I was Interested in Environmental Engineering but didn’t want to
        go for research or higher studies. Then I started exploring coding stuff again after hearing from peers that the
        majority of the
        companies in placements are in the coding domain. I did one or two online courses and then did a summer project
        in ML. The project was interesting to me not because of ML but because it included principles of cognitive
        science and behavioral
        therapy. Parallel to that, I was doing microeconomics and was developing an interest in it. After the course was
        over, I visited the instructor and asked for his advice. He was very kind-hearted and guided me on how to decide
        if I want to make
        a career in that domain. He suggested some courses, asked to explore and then meet again.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Gourav-Kamboj/3.jpg"/>
      <p>
        In my 5th sem, I did some courses in Finance and It got me thrilled. After doing some more core courses, I
        started to eliminate the domains that I was not interested in. I didn’t want to go into research and I wasn’t
        enjoying the labs either.
        Also, hardly 1-2 core companies come for internships and my CPI wasn’t too good to get into them. So, the core
        was out of the list. Doing so, I was left with Finance and Coding. At the same time, I was in the organising
        team of Udghosh’19 as a
        manager with lots of work and responsibilities.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Gourav-Kamboj/4.jpg"/>
      <p>
        Many of my friends had started preparing for internships and I was still struggling to decide what to choose,
        Finance or Coding. As many of the companies that are open for our dept. are of Coding profile, so I started
        preparing for the same. I
        was bad in aptitude but underestimated it. My friends were performing well and I was struggling to clear my
        concepts and speed was the worst. I got confused whether to practice aptitude or solve programming questions.
        Then switching between
        these two most of the time. I got shortlisted by 4 companies for the coding profile. But I didn't make it into
        either of them because my interview preparation was also not good. I remember, Udghosh’19 was ongoing and I was
        managing the event. I
        got an interview call for Deskera at the same time. But I didn’t make it into this because of a lack of
        interview preparation. I started applying on LinkedIn after the first phase because, In the second phase of SPO,
        much fewer companies were
        coming for the intern. I got a Finance internship through LinkedIn in a Bangalore-based company. Then comes the
        Pandemic. Some of the companies revoked their offers including big ones like Gartner. However, I completed an
        online internship.
        Then I got another internship in Data Science. I was confused about whether to prepare for placement or go for
        the internship. After talking to my friends, I chose the latter one. Many of the students decided to go for
        double major because it
        was clear that a big no. of companies won’t be coming and also, they will be hiring less no. of people. But I
        was sure that I’m going to sit for placement.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Gourav-Kamboj/5.jpg"/>
      <p>
        It was again difficult for me to decide for which role I will be preparing… Came up with a list including more
        no. of profiles (analyst, product and operations, Finance, coding) in the order of interest.
      </p>
      <p>
        I contacted some seniors to know about the different profiles they were in. Thanks a lot to Arnav Garg, Saisha
        Narang, Prajual Maheswari, Anmol Gupta, Shubham Dhaneria, Pratyush Rai, Anis Majhi for helping me out. After
        preparing the resume for
        different profiles, I got it checked by some seniors (you should do it).
      </p>
      <p>
        Practicing every day felt like JEE days. Then tests started at the end of September. The number of tests
        gradually increased within a month. Sometimes I was sitting continuously for 2-3 tests a day. I got shortlisted
        for BlackRock on day 1 and
        HSBC on day 3. I reached out to seniors for the final gyaan, thanks to Padma Tashi and Komal Raj for their
        constant support. Then the most awaited time came, ‘DAY1’. I gave the first round of BlackRock. I was the last
        one to give an interview
        because of bad internet connectivity. The interviewer was already prepared with his list seeming to have no
        interest in the conversation.
        <img class="blog-image-right" src="placement-postulates/blogs/Gourav-Kamboj/6.jpg"/>
        This was what I felt. Unfortunately, I wasn’t
        selected for further rounds. The whole night passed in thinking where I made the mistake. Maybe I wasn’t
        confident enough to defend myself or something else. Then the mind was full of negative thoughts as I wasn’t
        selected for an interview on
        day 2. On the evening of the 2nd day, I got a call for Flipkart interview (for their product role). I was on the
        waiting list. Around 8 pm, I gave the first round. The interviewer asked for a case study. There were 3 possible
        solutions, I
        figured out only one. Hence, I didn't get into the next round. The situation of my mind was like it could blast
        anytime, leaving no space to escape. But, here comes the twist to the story. I did a mock interview with two of
        my wingies.
        Heartiest thanks to Tanishq Patil and Akshat Agrawal for helping me on that day. It boosted my confidence and
        helped me to prepare better for the next day. The night again passed in preparation. I slept for 2-3 hrs only.
        Day 3 came, I gave the
        first round (technical) of HSBC. After the first round, I had a strong feeling that I’m going to make it today
        as I had prepared better this time. 15-20 mins later, I got the call for the second round (HR). Both the
        interviewers were quite nice
        to me. The whole interview was fun. In the end, I asked my friend to check from SPO whether I got selected or
        not. Many thanks to Gurpreet and Vedant for your helping hands. The moment I came out of my room, mom looked at
        my face and I smiled…
        and said it’s done, I did it. Now you don’t have to make tea all day for me 😊. A big thanks to my family,
        seniors and friends who motivated me throughout the journey.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Gourav-Kamboj/7.jpg"/>
      <p>
        Few points to keep in mind:
      <ul>
        <li>
          Get some POR’s and make sure you learn as much as possible.
        </li>
        <li>
          Try to keep the placement sem light if possible.
        </li>
        <li>
          Internships help more in the industry than projects under prof. in general. But this isn’t the thumb rule.
          Some people didn’t get an internship but they did well in placements.
        </li>
        <li>
          Get your resume checked by seniors before submitting it.
        </li>
        <li>
          Reach out to seniors, friends whenever you feel their need. Don’t Shy. It’s your career and you should ask for
          help. Nobody is going to spoon-feed you.
        </li>
        <li>
          Start your placement preparation ASAP. Practice daily with at least 4-5 questions (if going for a coding
          profile). The time depends on an individual’s skill set and how better you can do the job. I’ll suggest
          starting before June end if you
          are doing an internship or else start in Apr-May.
        </li>
        <li>
          Mock interviews give better insights though are not necessary.
        </li>
        <li>
          Try to give your interview as early as possible. Don’t think you will get more time to prepare. The more you
          delay, your chances decrease. It will also be helpful for walk-in interviews as the deadlines are quite tight.
        </li>
      </ul>

      </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gourav_Kamboj