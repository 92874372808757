import React from 'react'
import './blogs.css'
const Krishna_Deo = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Krishna-Deo.jpg' className='blog-dp' alt='aryaman'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Krishna Deo</h2>
                            <small>Business Analyst at Sutra Management Consultancies</small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>Greetings everyone,
        <br/>
        I am Krishna Deo(Y17). I completed my Btech in Civil engineering(from IITK, obviously) this year. I will be
        joining Sutra Management Consultancies as a Business Analyst. In my 3rd Year, I did my Internship at ZestMoney
        as a Product Management
        intern(SPO se lagi thi). I was an Internship Coordinator in my 3rd year. In my 2nd year, I was a company
        coordinator at SPO. Also, I was an integral part of Hindi Sahitya Sabha (although I was a secretary, I share a
        much greater bond there).
      </p><img class="blog-image-left" src="placement-postulates/blogs/Krishna-Deo/1.jpg"/>
      <p>
        I was not much into academics, bas pass hone ke liye exam se pehle padhta tha. That is why I barely crossed 7
        cpi at the end of my degree(thanks to corona- only for my cpi). During the Internship season, I wasn’t sure
        about the profile I want
        to go for, bas itna pata tha ki coding NHI karni. I prepared only for aptitude tests during the 2nd-year summer.
        That is why I cleared almost every test during my internship season. Waise test ke liye shortlist bhi gine chune
        companies me hi
        hua tha kyunki cpi 6.5 thi, but got rejected in the interview because I didn’t have any Skills. I realized that
        Skills are more important than the points that you write in the resume. In my 2nd year, my whole focus was to
        build pointers for my
        resume, so I only gathered internships and made my resume. But in the interview, I was not able to show any
        relevant skills. So, at last, I started reading about product management and a few books related to it and got
        an internship pertinent
        to it.
      </p>
      <p>
        After my 3rd year, I started making my resume for the placement season. I have seen people making resumes for
        each profile they want to sit for, which I also believe is important. But I was particular to specific profiles
        only. So, I made only
        one resume and applied mainly in Product Management, consultancy, and Analyst profiles.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Krishna-Deo/2.jpg"/>
      <p>
        In my opinion, the role of a resume comes during the interview. There are only a few companies that go through
        the resume before the interview, mostly shortlist based on tests. During the interview, your knowledge of what
        you did in the
        internships/projects will be reflected by the resume and how you explain them. The template for the resume is
        almost similar for most people in the college, so I asked for a template from a senior and edited it. Editing in
        the word was much
        easier than editing in Latex. Many people use Latex to make resumes, but using MSword will save a lot of time. I
        tried to edit it asap and reviewed it from a lot of seniors. Reviewing the resume is the most important part,
        every senior pointed
        out some mistakes that helped me come out with the final draft. You can view my resume <a
          href="https://drive.google.com/file/d/1aPCuVylBXfBfNldLTe3WklbwZ1mIPykM/view?usp=sharing"
          class="links">here</a> since most of the students were at home
        due to covid and may not have an internship or projects to write in the resume. In this case, doing
        self-projects and learning relevant skills will help a lot. You can mention any self-project in your resume.
      </p>
      <p>
        I started my preparation for the placements at the start of August. First, I decided on the profiles that I want
        to sit for. There are almost similar profiles as there were in the internship drive, but the number of companies
        was much more than
        the internship time. I decided to sit for Product Management, Consulting, and Analyst roles. I consulted a few
        seniors regarding the preparation strategy. I am writing all the pointers that I got for the preparation
        strategy for consulting and
        Analyst roles as both need very similar types of preparation-
      <ol>
        <li>
          Aptitude (quant, LR, DI)
        </li>
        <li>
          Probability (<a href="https://mbapreponline.files.wordpress.com/2013/07/fifty_challenging_problems_in__2.pdf"
            class="links">Fifty challenging questions</a>)
        </li>
        <li>
          Guesstimates (Start with Case interviews cracked and then some other resource (<a class="links"
            href="https://drive.google.com/drive/folders/1nYlMGsY6BsGVstLtDqxx8BqJx46UYoYK">find here</a>))
        </li>
        <li>
          Case studies (Start with victor cheng youtube videos (<a
            href="https://drive.google.com/drive/folders/0B2qQx5DAQoeIUUJaRHNudnZzYjg?resourcekey=0-7wy0ayNf1Rg7ed2vZAqnpA"
            class="links">find here</a>) and then Case interviews cracked)
        </li>
        <li>
          Puzzles (Geeks for geeks-first 60-70 puzzles are sufficient)
        </li>
        <li>
          SQL (Start with w3schools (<a class="links" href="https://www.w3schools.com/sql/default.asp">here</a>) and
          then at the end read this (<a class="links" href="https://www.interviewbit.com/sql-interview-questions/">Sql
            que</a>))
        </li>
        <li>
          C++ (because some companies for analyst roles gives one or two coding questions)
        </li>
      </ol>
      </p>
      <p>
      <img class="blog-image-left" src="placement-postulates/blogs/Krishna-Deo/3.jpg"/>
        I doubted the coding thing. I started coding again. I did a course of DSA offered by Demux Academy. I did coding
        for almost a month with full honesty, but after that, I thought it was not of that much help because I was not
        doing well in it, so
        I left coding there. I was doing my Aptitude practice simultaneously. I did a lot of aptitude practice mainly
        from prepleaf, pariksha, CAT book (<a class="links"
          href="https://www.flipkart.com/prepare-quantitative-aptitude-cat-2020/p/itmezunpq3y4tzck?pid=9789353160180&lid=LSTBOK9789353160180FIDBS5&marketplace=FLIPKART&q=quantitative+aptitude+for+CAT&store=bks&spotlightTagId=BestvalueId_bks&srno=s_1_2&otracker=search&otracker1=search&fm=SEARCH&iid=9a6da94b-26fe-437e-ba68-94848b399743.9789353160180.SEARCH&ppt=sp&ppn=sp&ssid=ddry8hvx340000001624027184922&qH=2ad53808d1828c39">This
          one</a>) - initially, I solved it page by page, but after that, I solved only those topics in which I faced
        any problem. I used to give one test daily either from Pariksha or prepleaf and used to analyze that very
        carefully, and in whatever
        section or question type I faced any problem, I referred to the CAT book or random online sites to read about
        that topic. For LR and DI, I referred to the previous year’s CAT questions.
      </p>
      <p>
        I was preparing for Product Management roles side by side. For Product Management, I followed few resources -
      <ol>
        <li>Decode n Conquer</li>
        <li><a class="links" href="https://www.youtube.com/channel/UCjm_qVkCPjOVDz9BWjNqO9A">Exponent</a> (youtube
          channel)</li>
        <li>Guesstimates</li>
        <li>Cracking the PM interviews</li>
      </ol>
      </p>
      <p>
        The placement tests started somewhere in October(after the midsem); although it was a bit late last year, it
        generally begins after the midsem. I spent more time on aptitude practice, but I should have started the other
        things earlier. In my
        opinion, Guesstimates and Case studies should be started around September, but I started it in October. For
        cases and guesstimates, try to form a group of 3-4 friends(active) and do it together. Otherwise, you can do it
        alone as well but be
        disciplined with your preparation. For puzzles, a senior suggested to me a very good strategy. I was doing
        around 3-4 puzzles every day. The strategy was- a) read the question, b)Think for the solution c)If not able to
        solve it, see the
        solution d)Copy the question in a doc and leave some space for the solution. I did it for all the puzzles. At
        last, I printed out that doc and wrote all the answers by myself. Before interviews, I only used to see those
        printed pages, and it
        was much easier than going through the website again.
      </p>
      <p>
        Time management is very important during the preparation. Try to keep the semester credits as minimum as you
        can. Try to utilize as much time as you can for the preparation. Shift yourself in the JEE mode again.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Krishna-Deo/6.jpg"/>
      <p>
        My Journey after the placement tests started-<br/>
        Initially, I was not able to give the tests of many companies as my CPI was below 7. Last year many companies
        increased their CPI cutoff. I gave the tests for many companies like EXL, Mastercard, Axis Bank, Alphonso, Navi,
        etc. We started to
        get the shortlist for the companies around November start. I was not getting shortlisted in any company. I was
        shocked because in my head, my tests were going well and I was able to solve more than 90% of the questions.
        After that, I got to
        know about many other things going on during the tests. There was a discord group of around 1000 people from
        different IITs, and many things were going on there. Many companies like Axis gave the same questions in every
        IIT, and people had the
        questions before the tests. Many more things were happening like this.
      </p>
      <p>
        During my test for Indus insight, my net got disconnected for a second, and I was kicked out of the test by the
        test engine. I got shortlisted for the GD of Alphonso but my connection was weak during the GD and I was out of
        the discussion for
        around 5 out of 10 minutes GD. I am telling these things because these things hampered my mental state. So, I
        would suggest being ready for incidents like these. I was not shortlisted for any companies on Day1. I was
        shortlisted for two
        companies on Day2, which were Sterlite and Axis Bank(Product role). Sterlite was a core role, so I was not
        expecting anything from it. Axis Bank interviewed me for only 10 minutes and I got to know that this happened
        with others too. I got
        rejected there as well. On Day3, there was only one company for a non-core role for which I was eligible i.e
        ICICI Bank. I gave the GD and got shortlisted for the interview. After that, I gave the interview, and then they
        told me that my
        candidature was canceled because I forgot to fill a form that they circulated two weeks ago. By this, I want to
        tell you guys to be attentive all the time and don’t forget to fill any form that comes in the placement portal.
        I was very much
        depressed. I cried during the whole day3 because, in my mind, I thought that I would be placed in the first two
        or three days. I convinced myself that this was not the end of life, and I was ready to not get placed. On the
        morning of the day4,
        the shortlist was published in the placement portal for that day’s companies. I got shortlisted for almost every
        non-core profile for that day. I was interviewed for Sutra management, FCS, testbook(4 profiles), axxela, Gain
        credit and one more
        company. I was giving the interview for the whole day. I did not give the testbook interview because of the time
        constraint. I gave 3 rounds for Sutra, 4 rounds of Gain credit, GD and interview for Axxela, interview of FCS,
        and one round for
        two profiles of testbook. In the end, I got placed on Day4.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Krishna-Deo/8.jpg"/>
      <p>
        I would suggest you not think of anything beforehand and do not presume things. Just be ready to grab any
        opportunity that you get. There will be fewer opportunities because of many things like- many companies will not
        shortlist you because of
        your branch(in my case- Deloitte did not shortlisted students from Civil), because of your CPI, and many other
        things. So just be ready for all these things.
      </p>
      <p>
        Some final points that I would like to share-
      <ol>
        <li>
          During the interview, don’t worry about the results. Just think about the question that the interviewer is
          asking and make it a healthy conversation.
        </li>
        <li>
          Prepare for HR questions. For ex-, Introduce yourself, etc. You can go through prepleaf videos(<a
            class="links" href="https://www.youtube.com/watch?v=-zF9nQVRD4g">Find here</a>). This is a very important
          part that I took very lightly. In
          every interview, you will get started with these questions, and it will make a difference.
        </li>
        <li>
          Prepare your resume very well. Write down the points about the internship/projects/Por etc. that you have in
          your resume and practice them with your friends so that you can be fluent while explaining it during the
          interview.
        </li>
        <li>
          Don’t worry about not getting enough opportunities relative to your friend because of the department, CPI,
          etc., and just be ready to grab any opportunity that you get.
        </li>
        <li>
          The journey of placement preparation is itself very important. You will learn many things during this. Just
          get started, and you will realize at the end that “ Safar khoobsurat hai manzil se bhi”
        </li>
      </ol>
      All the Best. You can reach out to me for any help you want.
      </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Krishna_Deo