import React from 'react'
import './blogs.css'
const Ashutosh_Ranjan = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/blogs/Ashutosh-Ranjan/8.jpeg' className='blog-dp' alt='aryaman'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Ashutosh Ranjan</h2>
                            <small>TATA Projects Limited</small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>Hello Everyone!</p>
      <p>My name is Ashutosh Ranjan, Y17, BTech in Civil Engineering, IITK.
        First, I will give a brief introduction about my curriculum.
        I am a fourth-year student and got placed in TATA Projects Limited in placement season 2020-21.
        Talking about my academic background, I am dedicated to it, which helps me maintain my CPI of 8.9 until
        the 7th semester.
        I was also not so much indulged in the extra-curricular activities,
        though I participated in some events during Takneek and Techkriti in my first year.
        My friend and I participated in the Bridge Design Competition in Techkriti and
        were awarded the Certificate of Distinction for the same. This imparted a sort of interest in civil
        engineering.
      </p> <img class="blog-image-left" src="placement-postulates/blogs/Ashutosh-Ranjan/2.jpeg"/>
      <p>My first semester didn’t go well, so I decided not to involve myself in any extra-curricular activities
        other than those mentioned above. Finally, in my second year, I became part of the Descon Hobby group as
        Secretary and we also did one project in the summer. Later I also did the SURGE summer internship at the
        end of my second year under the supervision of Prof. Chinmoy Kolay. Yes, I enjoyed doing that research
        project but I wanted to explore the non-core sector as well. So, I also prepared for the upcoming
        internship session at the beginning of the third year. My friends and I started to prepare for GDs,
        Aptitude, Coding questions, and interviews well before time which help me get an internship at OYO
        Hotels and Homes Pvt. Ltd. Yes, The CPI mattered a little bit as it helped me to get shortlisted for
        interviews for different companies.
      </p> 
      <p>
        In my third year, I also became the Coordinator of Society of Civil Engineers, which helped me in
        developing many skills. I learnt time management and work prioritization and most importantly handling
        the responsibilities which boosted confidence in me. Our ex coordinators were also very helpful who
        guided us in a very nice way to accomplish the activities around the year. But unfortunately after
        organizing the Civil Dept. Get-together, the lockdown was imposed and we could not do anything more. But
        I am glad that our current coordinators are managing their responsibilities in a much better way than
        expected in the lockdown period as well.
      </p> <img class="blog-image-right" src="placement-postulates/blogs/Ashutosh-Ranjan/5.jpeg"/>
      <p>
        Coming to my internship, my profile was Business Analyst and I was also a part of a project related to
        Machine Learning. That was a very great experience because that was work from home. I honed my skills by
        doing a couple of specializations related to machine learning and deep learning from Coursera which
        helped me excel in my internship. It was for around two months packed with lots of work. The project
        that I was doing was very interesting and I loved doing it. My mentors were also very helpful. After the
        successful completion of my internship, I was also offered PPO from OYO before the placements, but
        unfortunately, I was not able to accept it as I was expecting more in terms of CTC than they offered.
      </p> 
      <p>
        So that was the stuff related to me and my curriculum. Now I would be telling you more things related to
        placements. So, the first thing starts with resume making. I would recommend you to first read the
        guidelines related to your resume from the SPO website which will give you an idea of how you should
        design your resume. Also, various resume making sessions are organized by the AnC council and SPO as
        well, do attend it because it is very helpful. I will not be able to list all the points related to
        resume making but I will tell you one thing that always consult your seniors for this because their
        experience helps. Don’t let your resume look cluttered and crowded by filling in all the things which
        might not be that relevant. If you can explain every point on your resume with great confidence then it
        is not necessary to put all those explanations in detail in your resume. Also, I would recommend making
        different resumes for different profiles that you might be targeting. At the time of intern, many of us
        didn't have many important things that we can mention in our resume, but I will say it's better to do
        some projects under professors or even online projects would help. So, at least try to justify why there
        are not many things on your resume, which might be asked by the interviewer. You have to satisfy him/her
        with your reasons (might be academic pressure, PORs, or something else).
      </p> <img class="blog-image-left" src="placement-postulates/blogs/Ashutosh-Ranjan/4.jpeg"/>
      <p>
        Now coming to the profiles that are offered during the placement depends upon the companies you applied
        to. There are profiles like Analytics, Data Science, SDE, Finance, Consulting, Core, etc. The CTC
        offered in these profiles also varies as the challenges are different. Also, there are some restrictions
        to branches for some of these profiles, but most of the companies are open to all in the placement
        season except some Day-1 companies which are open for only CSE, MTH, EE. Moreover, tests for these
        profiles are different. Some of the consulting firms like BCG, Bain and Company, Mc Kinsey usually come
        before placement season starts and at that time you have a great opportunity to take lead from others if
        you are interested in these roles. Two things matter here: 1st is POR and 2nd is your command on case
        studies. If you are targeting these companies start preparing for business case studies with your
        friends in a group right from the summer. Other than this profile, the rest of the profiles generally
        have an aptitude test followed by a coding test. You need to be fast at aptitude, hence practice a lot.
        I would recommend you to take help from some platforms like Prepleaf, etc. to increase your speed. For
        the coding rounds, it's not a one day work. You have to continuously practice 3-4 questions per day of
        different difficulty levels. It is very important to understand the algorithms behind the code as in the
        actual test the question can be slightly modified.<img class="blog-image-right"
          src="placement-postulates/blogs/Ashutosh-Ranjan/7.jpeg"/> I would recommend doing the DSA
        course offered by the
        institute or if you are not able to get it then you need to do it from any other online platform because
        it is necessary if you are targeting SDE profile. Also, do practice last year questions asked by
        different companies which are available on interviewbit and geeks for geeks. Now if you are focussing on
        ML profile then you need to do some courses on it. You can take the course CS771A from the institute or
        you can do Basic machine learning courses from Coursera followed by deep learning specializations. If
        possible try to do some hands-on experience by doing some projects related to ML. Before doing ML course
        revise the Probability and Stats course which will help to understand things better. Some companies ask
        for ML coding questions, if you have done any project then it will be very easy for you. Most of the
        companies ask MCQs related to ML which are mostly concept based. I have not applied for finance roles so
        I don’t have much idea about it but again do consult your seniors who have been placed in that profile.
        If you are looking to apply for core companies then the first thing you have to keep in mind is that
        there are not so many options, as two or three companies (Tata Projects, Sterlite Tech, L&T, etc )
        usually come for placements but the CTC varies drastically. Only Tata Projects offers CTC of 15 LPA and
        the rest are lower than 10 LPA. So, do research and plan in advance, which profile you are focussing on
        and prepare accordingly. I have mentioned some important websites for the preparation. Do have a look.
        Start as early as you can because once the 7th semester starts you will not get enough time to prepare
        for placements. Utilize the summer very well because usually, the internships get over by the first week
        of July.
      </p> <img class="blog-image-left" src="placement-postulates/blogs/Ashutosh-Ranjan/8.jpeg"/>
      <p>
        Now I will share how I have prepared for my placements:
      </p>
      <p>
        <b>For Core Companies:</b><br/> I have not done any off-campus internship in the core sector, but done
        the SURGE summer internship at the end of 2nd year. I had maintained a good CPI of 8.9 after the
        six-semester because core companies generally look for a good CPI. For the interviews, I revised
        important courses from Structural engineering like Mech. of Solids, Structural Analysis, Steel and RCC
        Design, Construction Management, some courses related to my project. The most important point is that
        you should know everything about the project or internship that you have written in your resume and
        explain every point in detail.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Ashutosh-Ranjan/9.jpeg"/>
      <p>
        <b>For Non-Core Companies:</b><br/> Although I was not placed in the non-core sector, I had got offers
        from OYO(PPO)and Optum Global Pvt. Ltd. Since I was from Civil Engg., I preferred going to the non-core
        sector. But as far as preparations are considered I gave my 100%. I did Deep Learning Specialization
        from Coursera and a couple of other ML courses from Udemy. I also did CE784A (ML and Data Analytics for
        Civil Engg. Applications) from Institute. All these courses gave me enough confidence so that I can
        apply for an ML profile during placement. I just revised all the important points from these courses
        with a bit of practice in ML coding questions. Most importantly I revised HSO201A (Applied Prob. and
        Stats) from which lots of questions were asked directly in the tests and interviews. I also did Web
        Development and Android Development specialization from Coursera so that I can apply for these profiles
        as well. I practice quant questions from Prepleaf, Indiabix, etc. I have gone through most of the
        puzzles available at geek for geeks and brainstellar, these puzzles are directly asked in the
        interviews. Don’t need to mug up the solution just understand the concept behind it and try to explain
        in your way. Don’t let the interviewer have the perception that you already know the solution to the
        puzzle beforehand. I practiced lots of case studies from the resources available on the SPO’s website.
        Also, try to solve guesstimate by seeing youtube videos and other sources. Try to practice with your
        friends. One more thing, if the placement season goes online for this year as well then I would suggest
        you have mock interview sessions with your friends on zoom, g-meet, etc. and get used to it because
        sometimes it becomes awkward to have a conversation over the call which would have been much easier face
        to face.
      </p> <img class="blog-image-left" src="placement-postulates/blogs/Ashutosh-Ranjan/10.jpeg"/>
      <p>
        I would recommend keeping the course load as little as possible during the 7th semester because it is a
        very crucial semester. Overloading might be risky. Try to do 4-5 courses only in that semester and also
        be very particular about the courses. I would like to highlight that an internship is not mandatory for
        placement, but of course, it will give an edge to you as compared to others. I have one friend who was
        not able to get any internship neither from the SPO nor any off-campus, but you will be shocked to know
        that he landed up in a company with a CTC of 27 LPA and he was the only one to be selected by that
        company. So, never lose hope. Push yourself harder. Many people say that Aptitude is easy, you don’t
        need to give more time to it, but I will say that don’t underestimate it, many students are not
        shortlisted because of this, so try as hard as you can. If possible learn short tricks which will
        improve your speed. Practice from as many sources as you can. Do solve probability questions because
        they are most frequently asked.
      </p> <img class="blog-image-right" src="placement-postulates/blogs/Ashutosh-Ranjan/11.jpeg"/>
      <p>
        As far as my experience is considered I will share everything from the beginning. Although this time the
        whole process was online, there was no problem regarding the conduction of tests and interviews.
        However, it was even better because unlike on campus we have to go to NCL for tests and also go to the
        Outreach cell again and again for the interview in so much cold which would be very challenging and
        time-consuming. But this time we could give the tests and interviews from the comfort of our home. The
        whole process was very smooth and on time. I will give my placement insight both for core and non-core
        sectors:
      </p>
      <p>
        <b>Core interview:</b><br/> Only 1 round of interviews was taken. The question mainly related to project
        and SURGE internship was asked. It was a sort of HR interview. The interview lasted for around 35-40
        mins.
      </p>
      <p>
        <b>Non-Core interviews:</b>
      <ol>
        <li>Zomato (Profile- Associate Product Manager)- Interview lasted for about 15 mins. One guestimate was
          asked. Questions related to product managers like A/B testing, etc. were asked to explain with an
          example. I gave only 1 round.
        </li>
        <li> Axis Bank (Analyst)- Guesstimate: how many cars travel on the Delhi-Noida flyover daily. I gave an
          answer that was pretty close to the correct estimate. Puzzles were asked related to probability and
          Statistics. Few ML and Data Analytics questions were also asked related to the internship project.
          The interview lasted for about 40 mins.
        </li>
        <li>EXL (Consultant)- Guesstimate was asked how many flights are there in the air at this point. The
          discussion took about 15 mins. Few puzzles were asked too. I gave only the 1st round.
        </li>
        <li>Optum(ML Profile)- Questions related to Probability and stats were asked. Conceptual questions
          regarding various ML algorithms were asked in detail. 2 rounds of the interview were taken. In both,
          the rounds questions were of a similar type but a bit harder. Puzzles were also asked related to
          probability. Some were directly from geeks for geeks. Questions related to the internship project
          were asked.
        </li>
        <li>HSBC Bank (Trainee Soft. Engg.)- The interviewer was a very chill person. He just talked with me
          about the job that we would be doing at HSBC. I just went through my resume. The interviewer was
          very happy with the work that I did in my internship. Questions related to the internship project
          were asked. I was selected for the final round which I was not allowed to give as I was already
          selected in two companies
        </li>
      </ol>
      </p>
      <p>
        <b>General advice to students</b><br/>
        You have always heard that CPI doesn't matter from your seniors, which is not true. Understand it as
        early as you can. Try to maintain a CPI above 8 because if you are looking to go for the core sector
        then you need a high CPI. Even in the non core sector, companies don’t want to take 6 pointers, however,
        they might consider you if you have extraordinary talent. So to be on the safer side maintain a good
        CPI. Don't panic if you are not selected on Day1 because many good companies come after Day1. Don’t get
        disheartened that your friends have got selected and you are giving interviews and not getting selected.
        Back yourself, that you can do it and focus on yourself and your preparations. Just have confidence in
        yourself and prepare well. Reach out to seniors for their interview experience and guidance. Moreover,
        do practice for mock interview sessions with your friends before the actual interview, it will help a
        lot. Go through all the puzzles on geeksforgeeks and brainstellar because similar puzzles are asked. And
        revise as many times as you can. Best of luck with your placements!!
      </p>
      
      <p>
      <b>Links to all the preparation resources:</b>
      <ol>
        <li><a class="links" href="https://createmomo.github.io/2018/01/23/Super-Machine-Learning-Revision-Notes/"
            target="_blank">Super Machine Learning Revision Notes</a>
        </li>
        <li><a class="links"
            href="https://www.analyticsvidhya.com/blog/2017/01/ultimate-guide-to-understand-implement-natural-language-processing-codes-in-python/"
            target="_blank">Ultimate Guide to Understand Implement Natural Language Processing
            Codes in
            Python</a>
        </li>
        <li><a class="links"
            href="https://www.analyticsvidhya.com/blog/2016/09/40-interview-questions-asked-at-startups-in-machine-learning-data-science/"
            target="_blank">40 Interview Questions asked at Startups in Machine Learning Data
            Science</a>
        </li>
        <li><a class="links" href="https://colah.github.io/posts/2015-08-Understanding-LSTMs/"
            target="_blank">Understanding LSTMs</a>
        </li>
        <li><a class="links"
            href="https://www.analyticsvidhya.com/blog/2017/04/40-questions-test-data-scientist-machine-learning-solution-skillpower-machine-learning-datafest-2017/"
            target="_blank">40 Questions Test Data Scientist Machine Learning
            Solution Skillpower
            Machine Learning Datafest 2017</a>
        </li>
        <li><a class="links" href="https://brainstellar.com/puzzles/" target="_blank">Brainstellar
            Puzzles</a>
        </li>
        <li><a class="links" href="https://www.geeksforgeeks.org/puzzles/" target="_blank">GFG Puzzles</a>
        </li>
        <li><a class="links" href="https://www.prepleaf.com/placement-preparation-series" target="_blank">Data Science
            questions from Preapleaf</a>
        </li>
      </ol>
      </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ashutosh_Ranjan