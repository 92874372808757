import React from 'react'
import './blogs.css'
const Mradul_Chaudhary = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Mradul-Chaudhary.jpg' className='blog-dp' alt='aryaman'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Mradul Chaudhary</h2>
                            <small>Associate consultant at Samagra</small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>“Experience tells you what to do; confidence allows you to do it.”</p>
            <p>
                Hi, I am Mradul Chaudhary, incoming associate consultant at Samagra. 2 Most important attributes that
                flipped the cards for me were Confidence and Communication. My placement experience will be a little
                different from other blogs because I got placed off-campus before placement season.
                <img class="blog-image-left" src="placement-postulates/blogs/Mradul-Chaudhary/1.jpg"/>
            </p>
            <p>
                I do not have an exact time at which I can say that my preparation started from this moment. I think
                that events kept on happening from 1st year itself, and finally, all the dots got connected on the D-Day
                of Interview. Samagra was the company that was constantly at the back of my mind from the very start.
                This curiosity for this firm started after seeing two consecutive Gymkhana Presidents joining it. Being
                in 2nd year, one thing that was very clear to me was that software jobs are not for me, and coding will
                be the last skill that I will practice for placement purposes. Consulting started poking my interest,
                and in the winter vacation of 2nd year, I bought the Case Interview Cracked IITB book to learn and
                understand more about it. I did not practice cases at that time but got an overview of concepts and
                different sorts of problems that are being asked in the interview.
            </p>
            <p> <img class="blog-image-right" src="placement-postulates/blogs/Mradul-Chaudhary/2.jpg"/>

                <i> “Get out of your bed and interact with your peers and seniors; procrastinating over the phone will
                    slowly lead you to denial of you getting behind your group.” </i>

                Lockdown gave me enough time to explore various fields and get hands-on experience in them. Learning
                product management was the byproduct of boredom in lockdown, and everything in online mode allowed me to
                intern even during the semester. I did three internships in Niyo Solutions (Product Management), Coeus
                Consulting (Market research analyst), and Monkhub Innovations (Product) during the lockdown and one core
                civil engineering summer training at Rail India Technical and Economic Service during my 1st summer
                vacation, which helped me gain confidence and experience to share in an interview. I did try coding too
                for a long time, but it failed to generate my interest in it. For me, interest is something that if I am
                doing something for hours and not looking at the time ticking away or yawning at that moment, then I can
                say I am interested. But in the case of coding, even if codes were getting compiled and executed, each
                minute felt like an hour for me.


            </p>
            <p> <img class="blog-image-left" src="placement-postulates/blogs/Mradul-Chaudhary/3.jpg"/>
            </p>
            <b>Resume:</b>
            <p>
            No doubt a good resume is a must and a reflection of your past 3-3.5 years. A good resume should reflect all
            the qualities required in the job you are applying for via your experiences. My Resume was majorly based on
            my Internships and PORs. I had the above-mentioned three intern experiences showcased in one section. In
            another section, I had my POR of Festival Coordinator Udghosh’21, Manager Election Commission, and
            Coordinator Society of Civil Engineering. I did one finance and one consulting project, both under different
            organizations (none of IITK). But if you ask me did I get benefitted from my Resume during my Interview
            process – the answer is a big NO. I was not questioned even once from my Resume during the entire process of
            Samagra Hiring. Then was it worthless to work for these PORs and projects? No, it was worth the time and
            hard work that they took. When we say that POR matters, then it is not those black Ariel font letters
            written on that single white paper. It is the experience, confidence, and skill that you learn working for
            that POR. If a person has honestly worked in some Cell, council, or festival, then he/she automatically
            learns how to speak confidently in public, how to communicate your thoughts efficiently, how to deal with a
            stranger, how to handle pressure situations, speaking what other wants to listen, and the list goes on. No
            firm just reads a random POR and likes a candidate; it’s the attribute that takes the candidate across the
            river. If you have a level one POR but cannot even introduce yourself in one go without a fumble, then no
            point in your Resume is going to save you. And on the other hand, if you can confidently keep your head high
            and speak with a bold soothing voice, then I don’t think you need any POR to show your skills. Hence work
            hard to develop your skills not to fill one page; the page will automatically keep on getting points if you
            are honestly utilizing your time in IITK.
            </p>
            <p> <img class="blog-image-right" src="placement-postulates/blogs/Mradul-Chaudhary/4.jpg"/>

                As a part of my preparation, I did a case study from the CIC IITB book and Casebook Consult Club IIMA.
                In between, I also got highly invested in the preparation of Product management. This helped me in
                learning the art of breaking and structuring a problem. Product preparation helps you a lot in learning
                the ideation, and you get to know the various idea, their application, and their impact on the user’s
                life. Usually, in consulting interviews, you get case study questions similar to those given in the
                above-mentioned books. Another important aspect is that you should not read the case. Learn standard
                frameworks but do not stick to them; you should understand the approach for the framework. Few questions
                in the interview may need you to form your own framework. Just read one case of each category and after
                that, try solving cases as an interviewee with your friend/senior acting as interviewer. If you are
                going for governance consulting, then the approach that you learn from these books will help you solve
                the case, but the case asked in the interview will be very different than the usual because their work
                domain is to consult the government in making policies and its implementation. So questions will be
                based on real-life issues, existing policies, on-ground problems, and better ideas rather than standard
                profitability or supply chain cases. So, you should be equipped with current affairs (not very
                extensively) and an approach to real India. (Gen Z approach may not work, XD)

            </p>
            <p>
                Notification for Samagra hiring came around September. 1st step was to fill up the form with a 90-sec
                video of yourself speaking about your strength, weaknesses, and why do you want to join Samagra. 2 weeks
                later, I got the mail for 2nd round, where I was given a problem statement and had to make ppt within 24
                hrs. The problem statement was a real-life project of the UP government’s education policy. Since the
                problem statement was not the usual one that we are used to doing, hence I took the time and submitted
                ppt a few hours beyond the submission deadline. (<a
                    href="https://drive.google.com/file/d/1XIaspenLdUq84xhuwmCavzvnrgRa6WEV/view?usp=sharing"> Link for
                    my deck</a> ) The next round was the Managerial Round which was purely a Case study round involving
                questions of real-life estimations and a case study on the Haryana Skill & Development Sector. This
                round went for about 1.5 hours, and I was quite sure that I would be moving ahead in the next round.
                Finally, there was the Founder Interview which was to check pressure handling, ideation, thought
                process, attitude, confidence, etc. This went on for an hour, and then Gaurav Goel (Founder) said with a
                smile, “I want to extend you an offer.”
            </p>
            <p> <img class="blog-image-left" src="placement-postulates/blogs/Mradul-Chaudhary/5.jpg"/>

                Everyone may have a different interest in different domains. I had my interest in the development sector
                and governance consulting, which led my way to Samagra. But no matter what you are preparing, knowledge
                + soft skill is all you need to clear your path. I would like everyone to at least try every field and
                then make an informed decision as to where to focus upon. Don’t ever be afraid of competition, thinking
                that so many chaapu people are also applying for the same because, as far as I have experienced, being
                in the limelight in IITK is a different game (students think of them as chaapu), while actually knowing
                things and having relevant skill is a different story. If you have self-confidence that I can speak
                better or comparable than anyone else, then your 1st interview will be your last, given that you have
                knowledge relevant to the position you are applying for. Having a friend circle is important, and an
                inner core friends circle is a must. Have a few people by your side in whom you have confidence that
                these people will keep on standing even in your lows. Special mentions to Jasmine, Pruthviraj, and
                Deepak for being there continuously throughout the journey. Equations with parents may vary from person
                to person, but having a good equation with them is the cherry over the cake because no matter what, they
                are your biggest supporter and well-wisher.


            </p>
            <p>
                If anyone faces any sort of problem, be it personal, academic, or career, I’ll be more than happy to
                help. Never ever burden yourself with anxiety because, as we say, “Yaha Naukari toh sabki Lagti hai.”

            </p>
            <p>
                All the best, guys!

            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mradul_Chaudhary