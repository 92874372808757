import React from 'react'
import './blogs.css'
const Mudit_Kumar = () => {
    return (
        <div className='blog'>
            <div className='container-fluid'>
                <div className='row blog-row'>
                    <div className='col-md-4'>
                        <img src='placement-postulates/dp/Mudit-Kumar.jpg' className='blog-dp' alt='mudit'></img>
                    </div>
                    <div className='col-md-8'>
                        <div className='blog-head'>
                            <h2>Mudit Kumar</h2>
                            <small>Data Scientist at PayU</small>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div>
                        <p>
        Hello everyone, my name is Mudit Kumar, a Y17 B.Tech in Civil Engineering. I got placed on Day-2 in PayU as
        a Data Scientist. Talking about my academic background, I had a pretty bad start as I had an SPI of 6.6 in
        my 1st sem. I was disappointed as I knew that I could do better than this. So, in further semesters, proper
        planning and smart work helped me to improve my CPI and I am finally graduating with a decent cpi of 8.2.
        Apart from academics, I enjoyed participating in hall events, exploring different clubs and was also part of
        the DesCon hobby group as a Secretary.
      </p><img class="blog-image-left" src="placement-postulates/blogs/Mudit-Kumar/1.jpeg"/>
      <p>
        It was the summer of the second year when I realised that the internship season is coming and I didn’t even
        know whether I should opt for core or non-core. I saw the past year's stats and found that a very less
        percentage of Civil guys get an internship. The seniors too told us that getting an internship is difficult
        if you don’t know how to code. On the other hand, I hadn’t studied the ESC101A course seriously and had got
        a D in it. So I practically gave up and didn’t prepare much for the internship season.
      </p>
      <p>
        <b>DIDN’T GET AN INTERNSHIP BUT LEARNT A LOT FROM THE JOURNEY</b><br/>

        Finally, internship season started and as my CPI was decent, I got shortlisted in the tests of almost all
        companies. But the majority of the tests asked coding questions and I always ended up getting rejected after
        the test. Even after being shortlisted for the interviews of some companies, I couldn’t perform well in them
        as I wasn’t prepared. But this didn’t stop me from giving more and more tests. I saw many people like me who
        either gave up giving tests or they didn’t even apply for the companies from the beginning of the season
        itself. But this is a mistake people do which shouldn’t be done. Even if we are rejected after every test,
        and it is a hell of a stressful journey, a lot can be learnt in these times.
        Let me tell you what I gained from it. I came to know the importance of making a good resume, the importance
        of coding, the importance of puzzles and aptitude, the importance of preparing for the GD’s and interviews
        beforehand, and the pattern of the tests of different profiles. Also, the plethora of tests, the hectic
        academic load of the 5th semester and the rejections made me ready for the placement stress. But the most
        important thing was that I now knew the preparation needed for the placements.

      </p><img class="blog-image-right" src="placement-postulates/blogs/Mudit-Kumar/2.jpeg"/>
      <p>
        <b>FROM GETTING ‘D’ IN ESC101A TO GETTING ‘A’ IN ESO207A COURSE</b><br/>

        One good thing that happened to me was that I did an ML-related project in the summers which seemed
        interesting to me. I also did an online course to explore it and this field fascinated me. So, I decided to
        prepare for the data science-related profiles. But a big hurdle for me was coding needed for this field. I
        had to study data structures and algorithms myself. Honestly, I was scared of coding and after 1st sem, I
        was happy that I will never have to deal with it in Civil. But for this field, I had to do it. Thanks to my
        brother, Harshit Kumar (yeah I have a twin brother. He is also in IITK, in the CSE department). He helped me
        to get rid of my fears and guided me throughout the preparation. I realized coding is all about practice and
        patience, and this is what I didn’t do in the ESC101A course as laptops weren’t allowed in the 1st sem and I
        was too lazy to go to CC or NCL. Anyways I practiced a lot and more importantly regularly and got confidence
        in it. I did the ESO207A course in my last semester and got an A in it which is a big achievement for me. I
        have seen many Civil guys having fear of coding and this is primarily because they didn’t perform well in
        the ESC101A course. But in my opinion, in 1st year we are not that mature and we don’t study much as we just
        come after clearing JEE and want to enjoy our life. So, I want to tell you guys that if I can do it then you
        can too, you just have to give it a try. One thing to add is that if you’re practicing, don’t switch
        platforms. In my case, I did it from GFG and after completing it I moved on to Interviewbit.




      </p>
      <p>
        <b>CHOOSE THE RIGHT PROFILES AND SAVE TIME</b><br/>

        First of all, prepare for 2-3 profiles but have a strong foundation in at least 1 of them. Like in my case,
        I prepared for data science, software and finance profiles and had my strong foundations in data science.
        Let me tell you how it helps. This will help you to be focused and will save time that otherwise gets wasted
        in giving unnecessary tests which you will eventually fail to clear and even if you did most probably end up
        getting rejected in the interview as you haven’t prepared for it. Like in my case, I applied for product
        manager and consultancy roles too in some of the companies as they were reputed and had a high package but
        at the time of the interviews, it didn’t go that well and I ended up wasting my time which otherwise could
        have been utilised in proper sleeping or sometimes I could have given interviews of other companies having
        slightly less package but a profile that I had an interest in and had prepared well for.

      </p><img class="blog-image-left" src="placement-postulates/blogs/Mudit-Kumar/3.jpeg"/>
      <p>
        <b>PREPARE SMARTLY AND BE REGULAR</b><br/>

        Make a list of things you have to prepare and calculate the approximate time it requires, then see how much
        to do in a week and then finally analyse the time required for different things in a day; and prepare a
        proper routine for it. Smartly analyse what needs to be done when. After making the routine, it's important
        to follow it regularly. It will be difficult in the initial days and you may achieve your daily and weekly
        goals but then something happens and it stops the flow. But be motivated and try to be regular.
        For example, in my case, I had to do puzzles (approx. 150), aptitude (8-12 major topics), coding (300+
        problems), case study & GD preparation, 2-3 good projects (to compensate for the fact that I didn’t get an
        internship). If seen at once, it seems like a lot to do but when properly planned, I just had to do 3-4
        coding questions, 3-5 puzzles, 1 topic of aptitude in a day. Investing 8-10 hours a day will do the job. You
        have to plan smartly as there will also be time needed for resume making, regular course exams and the time
        you have to do projects where you have to give more time to them.
        <br/>
        One thing I want to add is to surround yourself with positive people and it would be good if you prepare in
        a group of not so many members. Fortunately, I had a group of 4 people (Ashutosh Sharma, Aman Jangid, Ishu
        Rajput and me), all preparing for similar profiles. These people kept me motivated and helped in keeping the
        stress away. We used to discuss different problems, rant about the tests, and take mock interviews of each
        other. Having similar goals helped in healthy competition, enhancing each one's knowledge and more
        importantly the whole group grew together.

      </p><img class="blog-image-right" src="placement-postulates/blogs/Mudit-Kumar/4.jpeg"/>
      <p>
        <b>APTITUDE, PUZZLES, HR AND GD PREPARATION</b><br/>

        Puzzles are very important. They are asked in almost every interview as well as in some of the tests. I will
        suggest doing it as early as possible and revise again a few weeks before the interviews.
        <br/>
        For aptitude, I would say they are mostly very basic and easy. We just forget some formulas and are a little
        slow in solving the questions. It just needs to be practiced regularly and the time will do its thing.
        Remember, a good amount of companies have only aptitude test.
        <br/>
        Many people have a fear of expressing themselves in English in interviews. I would say that these things
        improve over time and you can be confident in it by taking simple steps. Talk to your friends in English.
        Don’t hesitate to talk and also don’t laugh if someone tries. Another good way is to do GDs among friends.
        In GD you have to listen to everyone and you have to instantly think and express yourselves. So, constantly
        trying and not having the fear of being judged can improve your speaking skills a lot.
        <br/>
        HR preparation, GD & case study preparation and resume defending preparation can be done in the last 1 month
        before interviews. I am thankful to my wingies and friends (Pawan, Kartik, Pradeep, Ishu, Manish,
        Shailendra, Aman, Ashutosh, Vipul) who helped me to prepare for GDs, case studies, and guesstimates. Special
        thanks to my “IITK Bhai '' Arihant Jain who was not sitting for placements but helped a lot in GD sessions
        and other such activities.

      </p><img class="blog-image-left" src="placement-postulates/blogs/Mudit-Kumar/5.jpeg"/>
      <p>
        <b>IDEAL TIME TO START AND RESUME MAKING</b><br/>

        The earlier you start the better. I started my preparation in December after the 5th sem. But then had a
        break of 2-2 months in between. Overall it took me approx five months. So, even if you are late, try to
        start by the beginning of the summer. In case you are starting late and preparing from zero, you’ll have to
        work very hard and be consistent. In case you have an internship in summers through SPO, I assume you
        already have done something but if you haven't, you should start 1-2 months before internship as internship
        may take much time out of your summers. You can do the things you can do easily during an internship like
        puzzles, aptitude or some amount of coding.
        <br/>
        One thing I want to add is to keep the course load in the 7th sem light. It will help. And in the last 1-2
        months, you’ll be giving a lot of tests, it will be very exhausting and you may not get much time in those
        months. So, accordingly, keep the things for preparation that you can do easily in those times.
        <br/>
        Start making a resume at least 2 weeks before its submission deadline and get it reviewed by seniors or
        friends. Only write things you can defend and have confidence in answering about. Some of the companies in
        later days of placements only ask from resumes. So go prepare your resume well for different profiles. I
        will thank my brother and my friend Nimish Agarwal. They helped me a lot in making my resume. You can find
        my resume <a class="links"
          href="https://drive.google.com/file/d/1UPNlmdODvcbC9XWinM_GNwgGMWoDOg57/view?usp=sharing">here</a>.
      </p><img class="blog-image-right" src="placement-postulates/blogs/Mudit-Kumar/6.jpeg"/>
      <p>

        <b>INTERVIEWS</b><br/>

        I would say that you may have heard that CPI doesn't matter but believe me a good CPI will always give you
        an edge. You will be shortlisted for many tests and will have a good chance in walk-in interviews. Also in
        the case of core, CPI above 8 is favourable and sometimes even the shortlisting criteria for interviews. For
        non-core companies, try to have a 7.5+ CPI and you will be eligible for almost every test.
        <br/><br/>
        I had been shortlisted for the following companies-
        <br/>
        Day-1 : <br/>
        Blackrock- Asked from resume and basic HR questions but mainly wanted a guy with good communication skills.
        Rejected after round 1.
        <br/>
        Day-2 (Slot 1) : <br/>
        Fischer Jordan- Gave 3 rounds. Asked puzzles, guesstimates, probability questions and about projects on the
        resume. Reached the final round but got rejected. The company hired no one.

        Selected for EXL and Axis Bank (in waiting list) too but couldn’t give their interviews.
        <br/>
        Day-2 (Slot 2) : <br/>
        PayU- Total 3 rounds. Puzzles, ML questions and questions from projects were asked in rounds 1 and 2. The
        3rd round was the HR round. Asked basic HR questions.

        HiLabs- Puzzles, basic ML questions and questions from projects were asked in round 1. In round 2 coding
        questions were asked. By that time I was already selected for PayU.

        Sprinklr- Asked 1 guesstimate and a case study - “What will you change in Instagram to make it better and
        how will you do it.” It was for a product manager role and I wasn’t interested and didn’t perform well in
        it. Got rejected after the 1st round.

        Was also selected for Jio Saavn but couldn’t give their interview.
        <br/>
        After Day-2 : <br/>
        Selected for Tata (resume shortlist - asking for CPI greater than 8), Fareye, HSBC





      </p><img class="blog-image-left" src="placement-postulates/blogs/Mudit-Kumar/7.jpeg"/>
      <p>
        <b>
          USEFUL RESOURCES :</b><br/>
      <ul>
        <li>
          <a class="links" href="https://docs.google.com/document/d/1SayYHVAfId5mACsOp0a0bmHJC0wM42gEi8qoxEyav7A/edit">
            Sahil
            Dhull</a>
        </li>
        <li>
          <a class="links" href="https://www.interviewbit.com/puzzles/"> Interview Bit Puzzles</a>
        </li>
        <li>
          <a class="links" href="https://www.interviewbit.com/sql-interview-questions/"> SQL Interview Questions
          </a>
        </li>
        <li>
          <a class="links" href="https://prepare.prepleaf.com/dashboard/practice/5c9a660e01d3a533d7c16aaf">
            Quantitative Aptitude</a>
        </li>
        <li>
          <a class="links"
            href="https://www.youtube.com/watch?v=-zF9nQVRD4g&list=PLQqfhrN5hwqq2cc-00lE4lad1DWmd1_4c">Common HR
            questions by Prepleaf
          </a>
        </li>
        <li>
          <em>Case Interviews Cracked</em> book for Case Studies
        </li>
      </ul>
      </p>
      <p>
        <b>SOME ADVICE</b><br/>

        I liked the “Placement Postulate” written in my friend's blog (Krishan Kumar) and I will say that almost
        everybody went through the same experiences. Do give it a read.

        A senior (Rajat Singh Kharangar, Y15 Civil) once told me that “Getting placement is easier than an
        internship and only the ones who don’t even try don’t get placement”. I have seen it happening and will say
        the same. Yes, you might get a job with less package but you will get placed if you try. To get a good
        placement, you have to work hard too. So keep doing hard work and most importantly smart work.

        Thanks for reading this and I hope you find it useful. Feel free to ask me anything.
      </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mudit_Kumar