var team23 = [
  [
    {
      name: "Prof. Priyanka Ghosh",
      post: "HOD",
      fb: "https://www.facebook.com/profile.php?id=100005799362676",
      insta: "https://www.instagram.com/govind_gupta_46/",
      linkedin:
        "https://www.linkedin.com/in/priyanka-ghosh-33173314/?originalSubdomain=in",
      website: "https://iitk.ac.in/new/priyanka-ghosh",
      mail: "mailto:priyog@iitk.ac.in",
      image: "/team/team23/priyanka-ghosh-iitk.jpg",
    },
    {
      name: "Prof. Chunendra Sahu",
      post: "Faculty Adivsor",
      fb: "https://www.facebook.com/profile.php?id=100005799362676",
      insta: "https://www.instagram.com/govind_gupta_46/",
      linkedin: "https://www.linkedin.com/in/chunendra-k-sahu-b90aa33a",
      website: "https://www.iitk.ac.in/new/chunendra-k-sahu",
      mail: "mailto:cksahu@iitk.ac.in",
      image: "/team/team23/cksahu.jpg",
    },
  ],
  [
    {
      id: "2",
      name: "Aadya Umrao",
      post: "UG-Coordinator",
      fb: "https://www.facebook.com/profile.php?id=100082214229058&mibextid=ZbWKwL",
      insta: "https://www.secure.instagram.com/_aadyaaa_11/",
      linkedin: "https://www.linkedin.com/in/aadya-umrao-b63b9922b",
      mail: "mailto:aadya04umrao@gmail.com",
      image: "/team/team23/23cordi1.jpg",
    },
    {
      id: "3",
      name: "Sandipan Dutta",
      post: "UG-Coordinator",
      fb: "https://www.facebook.com/profile.php?id=100070465247144",
      insta: "https://www.instagram.com/imsandipandutta03/",
      linkedin: "https://www.linkedin.com/in/sandipan-dutta-6a03b6237/",
      mail: "mailto:sadipand21@iitk.ac.in",
      image: "/team/team23/c6.jpg",
    },
    {
      id: "4",
      name: "Saurabh Meena",
      post: "UG-Coordinator",
      fb: "https://www.facebook.com/saurabh.meena.10485?mibextid=ZbWKwL",
      insta: "https://instagram.com/saurabhmeena03?igshid=MzNlNGNkZWQ4Mg",
      linkedin: "https://www.linkedin.com/in/saurabh-meena-94600223b",
      mail: "mailto:msaurabh21@iitk.ac.in",
      image: "/team/team23/e6.jpg",
    },
    {
      id: "5",
      name: "Shambhavi Agrawal",
      post: "UG-Coordinator",
      fb: "https://www.facebook.com/profile.php?id=100075231073918",
      insta: "https://www.instagram.com/i_shambhavi/",
      linkedin: "https://www.linkedin.com/in/shambhavi-agarwal-15b47b242/",
      mail: "mailto:shambhavi21@iitk.ac.in",
      image: "/team/team23/23cordi8.jpg",
    },
    {
      id: "6",
      name: "Shrey Patel",
      post: "UG-Coordinator",
      fb: "https://www.facebook.com/shrey.patel.5243",
      insta: "https://www.instagram.com/shreypatel1408/",
      linkedin: "https://www.linkedin.com/in/shrey-patel-830bb164/",
      mail: "mailto:shreypatel21@iitk.ac.in",
      image: "/team/team23/23cordi4.jpg",
    },
    {
      id: "7",
      name: "Vedant Adlak",
      post: "UG-Coordinator",
      fb: "https://www.facebook.com/profile.php?id=100073822480156&mibextid=ZbWKwL",
      insta: "https://instagram.com/vedant_adlak21?igshid=NGExMmI2YTkyZg==",
      linkedin: "https://www.linkedin.com/in/vedant-adlak-4158a8257",
      mail: "mailto:vedanta21@iitk.ac.in",
      image: "/team/team23/23cordi5.jpg",
    },
    {
      id: "8",
      name: "Aditya Mishra",
      post: "PG-Coordinator",
      fb: "",
      insta: "https://instagram.com/a_dity_a_04?igshid=MzRlODBiNWFlZA==",
      linkedin: "https://www.linkedin.com/in/aditya-mishra-97042b19b",
      mail: "mailto:adityam22@iitk.ac.in",
      image: "/team/team23/23cordi6.jpg",
    },
    {
      id: "8",
      name: "Baban Kumar",
      post: "PG-Coordinator",
      fb: "https://www.facebook.com/baban594/",
      insta: "https://instagram.com/im_baban?igshid=MzRlODBiNWFlZA==",
      linkedin: "https://www.linkedin.com/in/imbaban/",
      mail: "mailto:babank@iitk.ac.in",
      image: "/team/team23/23cordi7.jpg",
    },
  ],
  [
    {
      name: "Thallapally Meghana",
      post: " Events",
      fb: "https://www.facebook.com/profile.php?id=100093860319405&mibextid=ZbWKwL",
      insta:
        "https://instagram.com/thallapally_meghana?igshid=MzNlNGNkZWQ4Mg==",
      linkedin: " ",
      mail: "mailto:tmeghana22@iitk.ac.in",
      image: "/team/team23/Events_Thallapally.jpg",
    },
    {
      name: "Pranay Saxena",
      post: " Events",
      fb: "https://www.facebook.com/profile.php?id=100006906991000&mibextid=ZbWKwL",
      insta: "https://www.instagram.com/pranay_s22/",
      linkedin: "https://www.linkedin.com/in/pranay-saxena-0971aa258/",
      mail: "mailto:pranays22@iitk.ac.in",
      image: "/team/team23/Events_Pranay.jpg",
    },
    {
      name: "Amrit Raj",
      post: " Events",
      fb: "https://www.facebook.com/profile.php?id=100086263431175",
      insta: "https://www.instagram.com/amritraj1705/?hl=en",
      linkedin: "https://www.linkedin.com/in/amrit-raj-458a03251",
      mail: "mailto:amritr22@iitk.ac.in",
      image: "/team/team23/Events_Amrit.jpeg",
    },
    {
      name: "Anurag",
      post: " Events",
      fb: "https://www.facebook.com/anuraj.raj.5?mibextid=ZbWKwL",
      insta: "https://instagram.com/anu17ag?igshid=MzNlNGNkZWQ4Mg==",
      linkedin: "https://www.linkedin.com/in/anurag-%E3%85%A4-602214267",
      mail: "mailto:anurag22@iitk.ac.in",
      image: "/team/team23/Events_Anurag.jpg",
    },
    {
      name: "Deepesh Maurya",
      post: "Web Development",
      fb: "https://www.facebook.com/dipesh.maurya.940",
      insta: "https://instagram.com/djshakya_?igshid=NGExMmI2YTkyZg==",
      linkedin: "http://www.linkedin.com/in/deepesh-maurya-386486251",
      mail: "mailto:deepeshm22@iitk.ac.in",
      image: " /team/team23/WebDev_Deepesh.jpg",
    },
    {
      name: "Manish Kumar",
      post: "Web Development",
      fb: "https://www.facebook.com/profile.php?id=100087166573544&sk=about",
      insta: "https://www.instagram.com/manish.kr.01/",
      linkedin: "https://www.linkedin.com/in/manish-kumar-733303264",
      mail: "mailto:kumarm22@iitk.ac.in",
      image: "/team/team23/WebDev_Manish.jpeg ",
    },
    {
      name: "Kuldeep",
      post: " Web Development",
      fb: " ",
      insta: "https://instagram.com/kuldeep_0_555?igshid=ZDc4ODBmNjlmNQ==",
      linkedin: "https://www.linkedin.com/in/kuldeep-singh-76295b256",
      mail: "mailto:kuldeepb22@iitk.ac.in",
      image: "/team/team23/WebDev_Kuldeep.jpeg",
    },
    {
      name: "Sahil Arolia",
      post: " Web Development",
      fb: " ",
      insta: "https://instagram.com/sahil_arolia?igshid=ZDc4ODBmNjlmNQ== ",
      linkedin: "https://www.linkedin.com/in/sahil-arolia-1a2bb8259",
      mail: "mailto:asahil22@iitk.ac.in",
      image: "/team/team23/WebDev_Sahil.jpg",
    },
    {
      name: "Gattu Tejaswini",
      post: " Content",
      fb: " ",
      insta: "https://instagram.com/tejaswi_0510?igshid=MzNlNGNkZWQ4Mg==",
      linkedin: "https://www.linkedin.com/in/tejaswini-gattu-a36b71281",
      mail: "mailto:tejaswinig22@iitk.ac.in",
      image: "/team/team23/Content_Tejaswini.jpg",
    },
    {
      name: "Harish Choudhary",
      post: " Content",
      fb: " ",
      insta:
        "https://instagram.com/choudhary_harish123?igshid=MzNlNGNkZWQ4Mg==",
      linkedin: "",
      mail: " ",
      image: "/team/team23/Content_Harish.png",
    },

    {
      name: "Pragyansh Mishra",
      post: "Content",
      fb: "https://www.facebook.com/profile.php?id=100093608971048&mibextid=ZbWKwL",
      insta: "https://instagram.com/mishra_pragyansh?igshid=NGExMmI2YTkyZg==",
      linkedin: "https://www.linkedin.com/in/pragyansh-mishra-0597b8264",
      mail: "mailto:pragyanshm22@iitk.ac.in",
      image: "/team/team23/Content_Pragyansh.jpg",
    },

    {
      name: "Singh Princekumar",
      post: "Content",
      fb: "https://www.facebook.com/profile.php?id=100087384218984",
      insta: "https://instagram.com/princekss22?igshid=MzNlNGNkZWQ4Mg==",
      linkedin: "https://www.linkedin.com/in/singh-princekumar-630bb21a9",
      mail: "mailto:princekss22@iitk.ac.in",
      image: "/team/team23/Content_Prince.jpg",
    },
    {
      name: "Shubham Kumar",
      post: " Design",
      fb: "https://m.facebook.com/profile.php/?id=100016442141532",
      insta: "https://instagram.com/focal15?igshid=OGQ5ZDc2ODk2ZA==",
      linkedin: "https://www.linkedin.com/in/shubham-kumar-8b8963255",
      mail: "mailto:kshubham22@iitk.ac.in",
      image: "/team/team23/design_shubham.jpg",
    },
    {
      name: "Aayush Sidana",
      post: " Design",
      fb: "https://www.facebook.com/aayushsidana2003?mibextid=ZbWKwL",
      insta: "https://instagram.com/aayush_sidana?igshid=NGExMmI2YTkyZg==",
      linkedin: "https://www.linkedin.com/in/aayush-sidana-ab3b5824a",
      mail: "mailto:aayushsi22@iitk.ac.in",
      image: "/team/team23/Design_Aayush.jpg",
    },
    {
      name: "Ramji Yadav",
      post: " Design",
      fb: "https://www.facebook.com/profile.php?id=100086706589545&mibextid=ZbWKwL",
      insta: "https://www.instagram.com/ramjiydv_iitk/",
      linkedin: "https://www.linkedin.com/in/ramji-yadav-iitk/",
      mail: "mailto:ramjiyadav22@iitk.ac.in",
      image: "/team/team23/Design_Ramji.jpg",
    },
    {
      name: "Gopal Tiwari",
      post: " Marketing & Finance",
      fb: "",
      insta: "https://instagram.com/gopal_tiwary_03?igshid=MzNlNGNkZWQ4Mg==",
      linkedin: "https://www.linkedin.com/in/gopal-tiwary-62245824b",
      mail: "mailto:gopalt22@iitk.ac.in",
      image: "/team/team23/Finance_Gopal.jpg",
    },
    {
      name: "Satyam Kumar",
      post: " Marketing & Finance",
      fb: "",
      insta: "",
      linkedin: "",
      mail: "",
      image: "/team/team23/Finance_Satyam.jpeg",
    },
    {
      name: "Vikranth Vavilapalli",
      post: " Marketing & Finance",
      fb: "",
      insta: "",
      linkedin: "https://www.linkedin.com/in/vikranth-vavilapalli-a18b77257/",
      mail: "mailto:vikranthv22@iitk.ac.in",
      image: "/team/team23/Finance_Vikranth.jpg",
    },
    {
      name: "Ruthvik Sai Kalahastri",
      post: " Marketing & Finance",
      fb: "https://www.facebook.com/kalahastriruthvik2005/",
      insta: "https://www.instagram.com/kalahastriruthvik_2005/",
      linkedin: "https://www.linkedin.com/in/ruthvik-sai-kalahastri-525100261/",
      mail: "mailto:ksruthvik22@iitk.ac.in",
      image: "/team/team23/Finance_Ruthwik.jpg",
    },
    {
      name: "Deepak Chaurasia",
      post: " Marketing & Finance",
      fb: " ",
      insta:
        "https://www.instagram.com/invites/contact/?i=gcnw6pr091lx&utm_content=li92gls",
      linkedin: "linkedin.com/in/deepak-chaurasia-136090250",
      mail: "mailto:deepakc22@iitk.ac.in",
      image: "/team/team23/finance_deepak.jpeg",
    },
  ],
];

module.exports = team23;
